import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Backdrop,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Formik
} from "formik";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import * as Yup from "yup";
import agent from "../../../../agent/agent";

const initForm = {
  "code": "",
  "ru": {"name": "", "name_one": "", "name_feed": ""},
  "en": {"name": "", "name_one": "", "name_feed": ""},
  "kaz": {"name": "", "name_one": "", "name_feed": ""}
};

class Stone extends Component {
  constructor(props) {
    super(props);

    const stoneId = props?.match?.params?.id;

    this.state = {
      stone: null,

      stoneId,

      isEdit: Boolean(stoneId),
      isLoad: true,
      isError: false,
      isBackdrop: false
    };
    this.refFormik = React.createRef();
  }

  componentDidMount = async () => {
    await this.getStone();
  }

  getStone = async () => {
    if (!this.state.isEdit) {
      this.setState({
        stone: {...initForm},
        isLoad: false,
      })
      return
    }

    const response = await agent.get(`/api/stones/${this.state.stoneId}?locale=ru`).then((res) => {
      return res.data?.data || null
    }).catch(() => {
      return null
    });
    if (!response) {
      this.setState({
        isLoad: false,
        isError: true,
      })

      return
    }

    const localeRu = (response?.translations || []).find((t) => t.locale === 'ru') || {};
    const localeEn = (response?.translations || []).find((t) => t.locale === 'en') || {};
    const localeKaz = (response?.translations || []).find((t) => t.locale === 'kaz') || {};
    const stone = {
      "code": response.code,
      "ru": {"name": localeRu.name || "", "name_one": localeRu.name_one || "", "name_feed": localeRu.name_feed || ""},
      "en": {"name": localeEn.name || "", "name_one": localeEn.name_one || "", "name_feed": localeEn.name_feed || ""},
      "kaz": {"name": localeKaz.name || "", "name_one": localeKaz.name_one || "", "name_feed": localeKaz.name_feed || ""}
    };

    this.setState({
      stone: stone,
      isLoad: false,
    });
  }

  onChangeFiled = ({target}) => {
    const {name, value} = target;
    const values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }
  onChangeForm = (values) => {
    this.refFormik.current.setValues(values)
  }

  onSubmit = async (values) => {
    this.setState({ isBackdrop: true });

    const apiAction = Boolean(this.state?.isEdit) ? 'put' : 'post';
    const apiMethod = Boolean(this.state?.isEdit) ? `/api/stones/${this.state.stoneId}` : '/api/stones';

    const response = await agent({
      method: apiAction,
      url: apiMethod,
      data: values
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    this.setState({ isBackdrop: false });

    if (Boolean(this.state?.isEdit)) {
      Notification({
        message: "Камень успешно изменен",
        type: NotificationTypes.success
      });
      await this.getStone();
      return
    }
    if (!Boolean(this.state?.isEdit)) {
      Notification({
        message: "Камень успешно создан",
        type: NotificationTypes.success
      });
      this.props.history.push(`/stones/${response?.data?.id}`)
    }
  }
  onSave = () => {
    this.refFormik.current.handleSubmit();
  }

  render() {
    const {
      stone,
      stoneId,
      isEdit,
      isLoad,
      isError,
      isBackdrop
    } = this.state;

    if (isLoad) {
      return <LoadingContent/>
    }
    if (isError) {
      return <ErrorContent/>
    }
    return (
      <>

        <Grid container alignItems="center" justifyContent="space-between" mb={2}>
          <Grid item>
            <Typography variant="h1">
              {isEdit ? 'Редактирование' : 'Создание'}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" onClick={this.onSave}>
              Сохранить
            </Button>
          </Grid>
        </Grid>

        <Formik
          innerRef={this.refFormik}
          initialValues={stone}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
        >{(props) => {
          const {
            values,
            errors,
            touched
          } = props;

          return (
            <Box borderRadius="10px" bgcolor="white" boxShadow="0 0 3px -1px black" p={2}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={4}>
                  <TextField
                    value={values.code}
                    label="Артикул"
                    size="small"
                    name="code"
                    fullWidth
                    error={Boolean(touched?.code && errors?.code)}
                    helperText={touched?.code && errors?.code}
                    onChange={this.onChangeFiled}
                  />
                </Grid>
              </Grid>

              <TableContent
                values={values}
                errors={errors}
                touched={touched}
                onChange={this.onChangeForm}
              />
            </Box>
          )
        }}</Formik>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

const TableContent = React.memo((props) => {
  const {
    values,
    errors,
    touched,
    onChange
  } = props;

  const handleChange = (lang, {target}) => {
    const {value, name} = target;

    let newValues = {...values};
    newValues[lang][name] = value;
    onChange(newValues);
  }

  return (
    <Table>
      <TableHead className="small">
        <TableRow>
          <TableCell width="120px">
            Язык
          </TableCell>
          <TableCell>
            Наименование
          </TableCell>
          <TableCell>
            Наименование для фида
          </TableCell>
          <TableCell>
            Наименование в единственном числе
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="small">
        <TableRow>
          <TableCell>
            RU
          </TableCell>
          <TableCell>
            <TextField
              value={values.ru.name}
              name="name"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="..."
              hiddenLabel
              error={Boolean(touched?.ru?.name && errors?.ru?.name)}
              helperText={touched?.ru?.name && errors?.ru?.name}
              onChange={handleChange.bind(this, 'ru')}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={values.ru.name_feed}
              name="name_feed"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="..."
              hiddenLabel
              error={Boolean(touched?.ru?.name_feed && errors?.ru?.name_feed)}
              helperText={touched?.ru?.name_feed && errors?.ru?.name_feed}
              onChange={handleChange.bind(this, 'ru')}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={values.ru.name_one}
              name="name_one"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="..."
              hiddenLabel
              error={Boolean(touched?.ru?.name_one && errors?.ru?.name_one)}
              helperText={touched?.ru?.name_one && errors?.ru?.name_one}
              onChange={handleChange.bind(this, 'ru')}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            EN
          </TableCell>
          <TableCell>
            <TextField
              value={values.en.name}
              name="name"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="..."
              hiddenLabel
              error={Boolean(touched?.en?.name && errors?.en?.name)}
              helperText={touched?.en?.name && errors?.en?.name}
              onChange={handleChange.bind(this, 'en')}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={values.en.name_feed}
              name="name_feed"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="..."
              hiddenLabel
              error={Boolean(touched?.en?.name_feed && errors?.en?.name_feed)}
              helperText={touched?.en?.name_feed && errors?.en?.name_feed}
              onChange={handleChange.bind(this, 'en')}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={values.en.name_one}
              name="name_one"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="..."
              hiddenLabel
              error={Boolean(touched?.en?.name_one && errors?.en?.name_one)}
              helperText={touched?.en?.name_one && errors?.en?.name_one}
              onChange={handleChange.bind(this, 'en')}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            KAZ
          </TableCell>
          <TableCell>
            <TextField
              value={values.kaz.name}
              name="name"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="..."
              hiddenLabel
              error={Boolean(touched?.kaz?.name && errors?.kaz?.name)}
              helperText={touched?.kaz?.name && errors?.kaz?.name}
              onChange={handleChange.bind(this, 'kaz')}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={values.kaz.name_feed}
              name="name_feed"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="..."
              hiddenLabel
              error={Boolean(touched?.kaz?.name_feed && errors?.kaz?.name_feed)}
              helperText={touched?.kaz?.name_feed && errors?.kaz?.name_feed}
              onChange={handleChange.bind(this, 'kaz')}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={values.kaz.name_one}
              name="name_one"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="..."
              hiddenLabel
              error={Boolean(touched?.kaz?.name_one && errors?.kaz?.name_one)}
              helperText={touched?.kaz?.name_one && errors?.kaz?.name_one}
              onChange={handleChange.bind(this, 'kaz')}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
})
const ErrorContent = React.memo(() => {
  return (
    <Box bgcolor="white" borderRadius="10px" padding="124px">
      <Typography variant="h1" color="error" align="center">E R R O R<br/>4 0 4</Typography>
      <Typography variant="h4" color="error" align="center" mt={2}>
        Запрашиваемый<br/>метал не найден
      </Typography>
    </Box>
  )
})
const LoadingContent = React.memo(() => {
  return (
    <Box bgcolor="white" borderRadius="10px" padding="124px">
      <Typography variant="h1" color="secondary" align="center">L O A D I N G</Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <CircularProgress color="secondary"/>
      </Box>
    </Box>
  )
})

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Заполните поле'),
  ru: Yup.object().shape({
    name: Yup.string().required('Заполните поле'),
    name_feed: Yup.string().required('Заполните поле'),
    name_one: Yup.string().required('Заполните поле'),
  }),
  // en: Yup.object().shape({
  //   name: Yup.string().required('Заполните поле'),
  //   name_feed: Yup.string().required('Заполните поле'),
  //   name_one: Yup.string().required('Заполните поле'),
  // }),
  // kaz: Yup.object().shape({
  //   name: Yup.string().required('Заполните поле'),
  //   name_feed: Yup.string().required('Заполните поле'),
  //   name_one: Yup.string().required('Заполните поле'),
  // }),
});

export default Stone
