import React from "react";
import {
  Header as HeaderComponent
} from "./components";
import {
  Box
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {compose} from "recompose";
import {connect} from "react-redux";

class Default extends React.Component {
  render() {
    const {
      classes,
      currentBuildVersion
    } = this.props;

    return (
      <Box>

        <HeaderComponent/>

        <Box height="calc(100vh - 64px)" overflow="auto" px={2} py={1} boxSizing="border-box" paddingBottom="60px">
          {this.props.children}
        </Box>


        <div className={classes.currentBuildVersion}>
          Время сборки:<br/>{currentBuildVersion}
        </div>
      </Box>
    )
  }
}

const styles = {
  currentBuildVersion: {
    background: "#22252D",
    borderRadius: "8px",
    position: "fixed",
    bottom: "10px",
    left: "10px",
    fontSize: "12px",
    padding: "4px 8px",
    color: "white",
    lineHeight: "16px"
  }
}
Default = withStyles(styles)(Default);


export default compose(
  connect(
    state => ({
      currentBuildVersion: state?.global?.currentBuildVersion || ""
    }),
    dispatch => ({}),
  ),
)(Default)
