import React from "react";
import {
  Grid,
  Chip,
  Divider
} from "@mui/material";
import {
  SectionInput
} from "../MainInfo";
const slugify = require('slugify')

class NameProduct extends React.PureComponent {

  onChangeForm = (locale, name, value) => {
    let values = {...this.props.values};
    values[locale][name] = value;
    if (name === "name") {
      values[locale]['locale_code'] = slugify(value, {
        lower: true,
        strict: true,
        replacement: "-"
      });
    }
    this.props.onChange(values);
  }

  render () {
    const {
      values,
      errors,
      touched
    } = this.props;

    return (
      <Grid container spacing={1}>

        {/* RU */}
        <Grid item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="RU"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['ru']['name']}
            title="Наименование"
            errors={errors?.ru?.name}
            touched={touched?.ru?.name}
            onChange={this.onChangeForm.bind(this, 'ru', 'name')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['ru']['name_one']}
            title="Наименование в единственном числе"
            errors={errors?.ru?.name_one}
            touched={touched?.ru?.name_one}
            onChange={this.onChangeForm.bind(this, 'ru', 'name_one')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['ru']['name_feed']}
            title="Наименование для фида"
            errors={errors?.ru?.name_feed}
            touched={touched?.ru?.name_feed}
            onChange={this.onChangeForm.bind(this, 'ru', 'name_feed')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['ru']['locale_code']}
            title="Локальный код товара"
            errors={errors?.ru?.locale_code}
            touched={touched?.ru?.locale_code}
            onChange={this.onChangeForm.bind(this, 'ru', 'locale_code')}
          />
        </Grid>

        {/* EN */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="EN"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['en']['name']}
            title="Наименование"
            errors={errors?.en?.name}
            touched={touched?.en?.name}
            onChange={this.onChangeForm.bind(this, 'en', 'name')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['en']['name_one']}
            title="Наименование в единственном числе"
            errors={errors?.en?.name_one}
            touched={touched?.en?.name_one}
            onChange={this.onChangeForm.bind(this, 'en', 'name_one')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['en']['name_feed']}
            title="Наименование для фида"
            errors={errors?.en?.name_feed}
            touched={touched?.en?.name_feed}
            onChange={this.onChangeForm.bind(this, 'en', 'name_feed')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['en']['locale_code']}
            title="Локальный код товара"
            errors={errors?.en?.locale_code}
            touched={touched?.en?.locale_code}
            onChange={this.onChangeForm.bind(this, 'en', 'locale_code')}
          />
        </Grid>

        {/* KAZ */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="KAZ"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['kaz']['name']}
            title="Наименование"
            errors={errors?.kaz?.name}
            touched={touched?.kaz?.name}
            onChange={this.onChangeForm.bind(this, 'kaz', 'name')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['kaz']['name_one']}
            title="Наименование в единственном числе"
            errors={errors?.kaz?.name_one}
            touched={touched?.kaz?.name_one}
            onChange={this.onChangeForm.bind(this, 'kaz', 'name_one')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['kaz']['name_feed']}
            title="Наименование для фида"
            errors={errors?.kaz?.name_feed}
            touched={touched?.kaz?.name_feed}
            onChange={this.onChangeForm.bind(this, 'kaz', 'name_feed')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['kaz']['locale_code']}
            title="Локальный код товара"
            errors={errors?.kaz?.locale_code}
            touched={touched?.kaz?.locale_code}
            onChange={this.onChangeForm.bind(this, 'kaz', 'locale_code')}
          />
        </Grid>
      </Grid>
    )
  }
}

export default NameProduct
