import React from "react";
import {
  Box,
  Grid,
  Chip,
  Divider
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  AutocompleteStones,
  AutocompleteMetals
} from "../../../../../../components";

class StoneMetal extends React.PureComponent {

  onChangeForm = (name, value) => {
    let values = {...this.props.values};
    values[name] = value;
    this.props.onChange(values);
  }

  render () {
    const {
      values,
      errors,
      touched
    } = this.props;

    return (
      <Grid container spacing={1}>

        {/* Камень */}
        <Grid item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="Камень"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionStone
            title="Камень"
            value={values.stone_id}
            onChange={this.onChangeForm.bind(this, 'stone_id')}
          />
        </Grid>

        {/* Металлы */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="Металлы"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionMetal
            title="Металлы"
            value={values.metal_ids}
            errors={errors.metal_ids}
            touched={touched.metal_ids}
            onChange={this.onChangeForm.bind(this, 'metal_ids')}
          />
        </Grid>


      </Grid>
    )
  }
}
const SectionStone = React.memo((props) => {
  const {
    title,
    value,
    onChange
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(value);
  }

  return (
    <Box className={classes.whiteBox}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={2}>{title}</Grid>
        <Grid item xs={10}>
          <AutocompleteStones
            value={value}
            size="small"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  )
});
const SectionMetal = React.memo((props) => {
  const {
    title,
    value,
    onChange,

    errors,
    touched
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(value);
  }

  return (
    <Box className={classes.whiteBox}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={2}>{title}</Grid>
        <Grid item xs={10}>
          <AutocompleteMetals
            value={value}
            size="small"
            multiple
            limitTags={999}
            onChange={handleChange}
            error={Boolean(touched && errors)}
            helperText={touched && errors}
          />
        </Grid>
      </Grid>
    </Box>
  )
});


const useStyles = makeStyles(() => ({
  whiteBox: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  }
}));

export default StoneMetal
