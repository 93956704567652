import React, {Component} from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import {
  LoadingButton
} from "@mui/lab";
import {
  withStyles
} from "@mui/styles";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import {
  Notification,
  NotificationTypes
} from "../../../common/Notification";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import errorAuth from "../../../errors/messages/errorAuth";
import {setCookie} from "../../../helper/cookie";

class Authorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: "",
        password: "",
      },

      verificationId: null,
      stage: 1,

      isLoading: false
    };

    this.refFormik = React.createRef();
  }

  componentDidMount = () => {
  }

  onSubmit = async () => {
    if (this.state.isLoading) {
      return null
    }

    await this.onAuth();
  }
  onAuth = async () => {
    this.setState({isLoading: true});

    const form = {...this.refFormik.current.values};

    const response = await agent.post('api/auth/login', {
      ...form
    }).then((res) => {
      return res
    }).catch((err) => {
      return null
    })
    if (!response) {
      localStorage.removeItem('token');
      agent.defaults.headers['Authorization'] = "";
      Notification({
        type: NotificationTypes.error,
        message: "Неверный логин или пароль"
      });
      this.setState({isLoading: false});

      return
    }

    localStorage.setItem('token', response?.data?.token);
    localStorage.setItem('form-auth', JSON.stringify(form));
    agent.defaults.headers['Authorization'] = `Bearer ${response?.data?.token}`;
    setCookie('admin-auth', '1');

    const user = response?.data?.user || {};
    this.props.setUser(user);
    this.setState({ isLoading: false });
  }

  changeForm = ({target}) => {
    const {name, value} = target;
    let newForm = {...this.refFormik.current.values};
    newForm[name] = value;

    this.refFormik.current.setValues(newForm);
  }

  render() {
    const {
      form,

      isLoading
    } = this.state;
    const {
      classes
    } = this.props;

    return (
      <Box display="flex" flexDirection="column" alignItems="center">

        <Box mb={8}>
          <Typography variant="h1">Авторизация</Typography>
        </Box>

        <Box flex="1" display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">

          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleSubmit,
              } = props;

              return (
                <Box width={640} px={8} py={4} className={classes.content}>
                  <form
                    onSubmit={handleSubmit}
                    onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                  >

                    <Box mb={4}>
                      <TextField
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        name="email"
                        label="Email"
                        placeholder="mail@mail.ru"
                        variant="outlined"
                        fullWidth

                        onChange={this.changeForm}
                      />
                    </Box>

                    <Box mb={4}>
                      <TextField
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        name="password"
                        label="Пароль"
                        type="password"
                        placeholder="****"
                        variant="outlined"
                        fullWidth

                        onChange={this.changeForm}
                      />
                    </Box>

                    <Box>
                      <LoadingButton
                        variant="contained"
                        size="large"
                        onClick={handleSubmit}
                        loading={isLoading}
                        fullWidth
                      >
                        Авторизоваться
                      </LoadingButton>
                    </Box>

                  </form>
                </Box>
              )
            }}
          </Formik>
        </Box>

      </Box>
    );
  }
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Некоретно введен Email').required('Заполните поле'),
  password: Yup.string().required('Заполните поле'),
});
const styles = {
  content: {
    background: "rgba(0, 0, 0, 0.03)",
    backdropFilter: "blur(50px)",
    borderRadius: 10
  },

  button: {}
};

export default withStyles(styles)(Authorization)
