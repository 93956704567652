import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Box,
  Grid,
  Tooltip,
  Checkbox,
  Skeleton,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from "@mui/icons-material";
import {
  HeadSortCell
} from "../../../../../../components";
import {
  convertorNumber
} from "../../../../../../helper/convertor";
import {
  useStore
} from "react-redux";
import {
  Link
} from "react-router-dom"
import moment from "moment";
import {getBodyApiFromClient, getBodyClientFromApi} from "../../../../../../helper/product";

const TableCustom = (props) => {
  const {
    data,
    categories,
    filter,
    totalPage,
    isLoad,
    onChange,
    onUpdateProduct,
    onDeleteProduct
  } = props;

  const handleChangePage = (event, newPage) => {
    let newFilter = {...filter};
    newFilter.page = newPage;
    onChange(newFilter, true)
  }
  const handleChangeSort = (sort) => {
    let newFilter = {...filter};
    newFilter.sort = sort;
    onChange(newFilter, true)
  }

  return (
    <>

      <Grid container justifyContent="flex-end" mb={2}>
        <Grid item><Pagination count={Number.parseFloat(totalPage || 0)} page={Number.parseFloat(filter.page)} onChange={handleChangePage}/></Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <HeadSortCell label="ID" filterSort={filter.sort} name="id" onChange={handleChangeSort}/>
            <TableCell>Фото</TableCell>
            <TableCell>Наименование</TableCell>
            <TableCell>Категория</TableCell>
            <TableCell>Код товара</TableCell>
            <HeadSortCell label="Цена (ед.)" filterSort={filter.sort} name="price" onChange={handleChangeSort}/>
            <TableCell align="center">Новинка</TableCell>
            <HeadSortCell label="Обновлен" filterSort={filter.sort} name="updated_at" onChange={handleChangeSort}/>
            <HeadSortCell label="Создан" filterSort={filter.sort} name="created_at" onChange={handleChangeSort}/>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(isLoad) && <RowLoading/>}
          {data.map((t) => <RowProducts
            key={`row-products-${t.id}`}
            product={t}
            categories={categories}
            onUpdateProduct={onUpdateProduct}
            onDeleteProduct={onDeleteProduct}
          />)}
        </TableBody>
      </Table>

      <Grid container justifyContent="flex-end" mt={2}>
        <Grid item><Pagination count={Number.parseFloat(totalPage || 0)} page={Number.parseFloat(filter.page)} onChange={handleChangePage}/></Grid>
      </Grid>

    </>
  )
}
const RowProducts = React.memo((props) => {
  const {
    product,
    categories,
    onUpdateProduct,
    onDeleteProduct
  } = props;
  const classes = useStyles();
  const apiHost = process.env.REACT_APP_HOST_API;
  const currentLocale = useStore().getState()?.languages?.locale;
  const isActiveNovelty = Boolean((product?.translations || []).find((t) => t.is_novelty));

  const handleChangeNovelty = async () => {
    let newProduct = await getBodyClientFromApi(JSON.parse(JSON.stringify({...product})));
    newProduct.ru.is_novelty = !isActiveNovelty;
    newProduct.en.is_novelty = !isActiveNovelty;
    newProduct.kaz.is_novelty = !isActiveNovelty;
    const body = await getBodyApiFromClient(newProduct)
    onUpdateProduct(body);
  }

  const _getFiled = (translations, name) => {
    const translation = (translations || []).find((t) => t.locale === currentLocale);
    return translation?.[name]
  }
  const _renderCodes = () => {
    const translations = product?.translations || [];
    return (
      <>
        {translations.map((translation) => (
          <Grid container alignItems="center" spacing={1} className={classes.rowLocale}>
            <Grid item>
              <span>{translation.locale}:</span>
            </Grid>
            <Grid item>
              <span>
                {translation.locale_code || '-'}
              </span>
            </Grid>
          </Grid>
        ))}
      </>
    )
  }
  const _renderPrices = () => {
    const translations = product?.translations || [];
    return (
      <>
        {translations.map((translation) => (
          <Grid container alignItems="center" spacing={1} className={classes.rowLocale}>
            <Grid item>
              <span>{translation.locale}:</span>
            </Grid>
            <Grid item>
              <span>
                {Boolean(translation.price) ? convertorNumber(translation.price) : '-'}
              </span>
            </Grid>
          </Grid>
        ))}
      </>
    )
  }
  const _imageId = () => {
    return (product?.files || [])
      .sort((a, b) => {
        if (a?.order < b?.order) {
          return -1
        }
        if (a?.order > b?.order) {
          return 1
        }
        return 0
      })
      ?.[0]?.url
  }

  return (
    <TableRow>
      <TableCell>
        {product.id}
      </TableCell>
      <TableCell>
        <Box className={classes.rowImage}>
          <img src={_imageId()}/>
        </Box>
      </TableCell>
      <TableCell>
        {_getFiled(product?.translations, 'name')}
      </TableCell>
      <TableCell>
        {categories.find((t) => t.id === product.category_id)?.name || "-"}
      </TableCell>
      <TableCell>
        {_renderCodes()}
      </TableCell>
      <TableCell>
        {_renderPrices()}
      </TableCell>
      <TableCell align="center">
        <Checkbox
          value={Boolean(isActiveNovelty)}
          checked={Boolean(isActiveNovelty)}
          onChange={handleChangeNovelty}
        />
      </TableCell>
      <TableCell>
        <div dangerouslySetInnerHTML={{
          __html: moment(product?.updated_at).format("DD.MM.YYYY<br/>HH:mm:ss")
        }}/>
      </TableCell>
      <TableCell>
        <div dangerouslySetInnerHTML={{
          __html: moment(product?.created_at).format("DD.MM.YYYY<br/>HH:mm:ss")
        }}/>
      </TableCell>
      <TableCell>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Tooltip arrow title="Редактировать">
              <IconButton color="info" component={Link} to={`/products/${product.id}`}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip arrow title="Удалить">
              <IconButton color="error" onClick={onDeleteProduct.bind(this, product, false)}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})
const RowLoading = React.memo(() => {
  return (<>
    {Array.apply(null, Array(10)).map((t) => (
      <TableRow key={`row-loading-${t}`}>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
      </TableRow>
    ))}
  </>)
})

const useStyles = makeStyles(() => ({
  rowImage: {
    position: "relative",
    overflow: "hidden",
    maxWidth: 160,
    "& img": {
      position: "absolute",
      top: "50%", left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "100%",
      maxHeight: "100%"
    },
    "&:after": {
      content: "''",
      float: "left",
      paddingTop: "100%"
    }
  },
  rowLocale: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      height: 32
    },
    "& img": {
      width: 24,
      height: 24
    },
    "& span": {
      fontSize: 16,
      lineHeight: "100%"
    },
  }
}));

export default React.memo(TableCustom)
