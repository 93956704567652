import React from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";

class Videos extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {}
  }

  changeForm = (value) => {
    let values = {...this.props.values};
    values.videos = value;
    this.props.onChange(values);
  }
  addNewLink = () => {
    let values = {...this.props.values};
    values.videos.push("");
    this.props.onChange(values);
  }
  deleteLink = (index) => {
    let values = {...this.props.values};
    values.videos.splice(index, 1);
    this.props.onChange(values);
  }
  changeLink = (index, val) => {
    let values = {...this.props.values};
    values.videos[index] = val;
    this.props.onChange(values);
  }

  render () {
    const {
      values,
      classes,
      errors,
      touched
    } = this.props;

    return (
      <Box className={classes.whiteSection}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h3">Видео</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {(values?.videos || []).map((video, index) => (
                <Grid key={`video-${index}`} item xs={12}>
                  <VideoElement
                    value={video}
                    onDelete={this.deleteLink.bind(this, index)}
                    onChange={this.changeLink.bind(this, index)}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  fullWidth
                  sx={{borderRadius: "4px"}}
                  onClick={this.addNewLink}
                >
                  Добавить видео
                </Button>
              </Grid>
            </Grid>
            {Boolean(errors?.videos && touched?.videos) && (
              <FormHelperText error={true}>
                { touched?.videos && errors?.videos }
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Box>
    )
  }
}

let timeOutUpdate = null;
const VideoElement = React.memo((props) => {
  const {
    value,
    onChange,
    onDelete
  } = props;
  const [localValue, setLocalValue] = React.useState(value);
  React.useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = ({ target }) => {
    clearTimeout(timeOutUpdate);
    setLocalValue(target.value || "");
    timeOutUpdate = setTimeout(() => {
      onChange(target.value)
    }, 500)
  }

  return (
    <Grid container spacing={2}>
      <Grid item sx={{flex: 1}}>
        <TextField
          value={localValue}
          size="small"
          label="Ссылка на видео"
          fullWidth
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="outlined"
          sx={{borderRadius: "4px"}}
          onClick={onDelete}
        >
          Удалить
        </Button>
      </Grid>
    </Grid>
  )
});

const styles = {
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  },
};
Videos = withStyles(styles)(Videos);

export default Videos
