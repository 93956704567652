import moment from "moment";

const convertorNumber = (value, decimalCount = 0, decimal = ",", thousands = " ") => {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = value < 0 ? "-" : "";

  let i = parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalCount)).toString();
  let j = (i.length > 3) ? i.length % 3 : 0;

  return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(value - i).toFixed(decimalCount).slice(2) : "");
}
const convertorDateToString = (date, format = 'DD.MM.YYYY HH:mm') => {
  date = moment(date).utcOffset(new Date().getTimezoneOffset(), true).utc();

  document.getElementById()

  return moment(date).format(format)
}

export {
  convertorNumber,
  convertorDateToString
}
