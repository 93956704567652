import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  AutocompleteProducts
} from "../../../../../../components";
import agent from "../../../../../../agent/agent";

class ProductIds extends React.PureComponent {

  addProductId = () => {
    let values = {...this.props.values};
    values.product_ids.push("");
    (async () => {
      await this.afterChangeProducts(values);
    })();
  }
  deleteProductId = (index) => {
    let values = {...this.props.values};
    values.product_ids.splice(index, 1);
    (async () => {
      await this.afterChangeProducts(values);
    })();
  }
  changeProductId = (index, event) => {
    const productId = event?.target?.value;
    let values = {...this.props.values};
    values.product_ids[index] = productId;
    (async () => {
      await this.afterChangeProducts(values);
    })();
  }

  afterChangeProducts = async (values) => {
    let products = [];
    for (const productId of values?.product_ids) {
      if (!!productId) {
        const product = await agent.get(`/api/products/${productId}?locale=ru`).then((res) => {
          return res.data?.data
        }).catch(() => {
          return null
        });
        if (!!product) {
          products.push(product);
        };
      }
    }
    values.products = products;

    const getPricesLocale = function (locale) {
      let price = 0;
      let oldPrice = 0;
      if (!price && (values?.products || []).length > 0) {
        (values?.products || []).map((product) => {
          price = price + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.price || 0);
          oldPrice = oldPrice + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.old_price || 0);
        })
      };
      return {
        price,
        old_price: oldPrice
      }
    };
    values.en = {
      ...values.en,
      ...getPricesLocale('en')
    };
    values.ru = {
      ...values.ru,
      ...getPricesLocale('ru')
    };
    values.kaz = {
      ...values.kaz,
      ...getPricesLocale('kaz')
    };

    this.props.onChange(values);
  }

  render () {
    const {
      values,
      errors,
      touched,
      classes
    } = this.props;

    return (
      <>

        <Box className={classes.whiteSection}>
          <Typography variant="h3" mb={2}>Товары которые входят в гарнитур</Typography>
          <Grid container spacing={2}>
            {(values?.product_ids || []).map((productId, index) => (
              <Grid key={`CustomRowProduct-${ productId }`} item xs={12}>
                <CustomRowProduct
                  value={productId}
                  onDelete={this.deleteProductId.bind(this, index)}
                  onChange={this.changeProductId.bind(this, index)}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={this.addProductId}
              >
                Добавить товар
              </Button>
            </Grid>

            {Boolean(errors.product_ids && touched.product_ids) && (
              <Grid item xs={12}>
                <FormHelperText error={errors.product_ids && touched.product_ids}>
                  {touched.product_ids && errors.product_ids}
                </FormHelperText>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box mt={3}/>

        <Box className={classes.whiteSection}>
          <Typography variant="h3" mb={2}>Товар входит в гарнитуры</Typography>
          <Grid container spacing={2}>
            {(values?.sets || []).map((productId, index) => (
              <Grid key={`CustomRowProduct-${ productId }`} item xs={12}>
                <CustomRowProduct
                  value={productId}
                  hideButton={true}
                  onDelete={this.deleteProductId.bind(this, index)}
                  onChange={this.changeProductId.bind(this, index)}
                />
              </Grid>
            ))}

            {Boolean(false) && (
              <>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={this.addProductId}
                  >
                    Добавить товар
                  </Button>
                </Grid>

                {Boolean(errors.product_ids && touched.product_ids) && (
                  <Grid item xs={12}>
                    <FormHelperText error={errors.product_ids && touched.product_ids}>
                      {touched.product_ids && errors.product_ids}
                    </FormHelperText>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>

      </>
    )
  }
}
const CustomRowProduct = React.memo((props) => {
  const {
    value,
    hideButton,
    onDelete,
    onChange
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={hideButton ? 12 : 10}>
        <AutocompleteProducts
          value={value}
          size="small"
          onChange={onChange}
        />
      </Grid>
      {Boolean(!hideButton) && (
        <Grid item xs={2}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={onDelete}
          >
            Удалить
          </Button>
        </Grid>
      )}
    </Grid>
  )
});

const styles = {
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  },
};
ProductIds = withStyles(styles)(ProductIds);

export default ProductIds
