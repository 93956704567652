import {changeFileSort, uploadFile} from "./files";

const getBodyClientFromApi = async (values) => {
  const inserts = (values?.inserts || []).map((insert) => {
    const ru = (insert?.translations || []).find((t) => t.locale === 'ru') || {};
    const en = (insert?.translations || []).find((t) => t.locale === 'en') || {};
    const kaz = (insert?.translations || []).find((t) => t.locale === 'kaz') || {};

    return {
      ...insert,
      country_ids: (insert?.countries || []).map((t) => t.id),
      ru,
      en,
      kaz,
    }
  });
  const file_ids = (values?.files || [])
    .sort((a, b) => {
      if (a?.order > b?.order) {
        return 1
      }
      if (a?.order < b?.order) {
        return -1
      }
      return 0
    })
    .map((t) => {
      return {
        ...t,
        fileType: t.type
      }
    })

  const getPricesLocale = function (locale) {
    let price = (values?.translations || []).find((t) => t.locale === locale)?.price || 0;
    let oldPrice = (values?.translations || []).find((t) => t.locale === locale)?.old_price || 0;
    if (!price && (values?.products || []).length > 0) {
      (values?.products || []).map((product) => {
        price = price + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.price || 0);
        oldPrice = oldPrice + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.old_price || 0);
      })
    };
    return {
      price,
      old_price: oldPrice
    }
  };
  const priceEn = getPricesLocale('en');
  const priceRu = getPricesLocale('ru');
  const priceKaz = getPricesLocale('kaz');

  let ru = (values?.translations || []).find((t) => t.locale === 'ru') || {};
  ru = {...ru, ...priceRu}

  let en = (values?.translations || []).find((t) => t.locale === 'en') || {};
  en = {...en, ...priceEn}

  let kaz = (values?.translations || []).find((t) => t.locale === 'kaz') || {};
  kaz = {...kaz, ...priceKaz}

  const product = {
    ...values,
    ru: ru,
    en: en,
    kaz: kaz,
    metal_ids: (values?.metals || []).map((t) => t.id),
    videos: (values?.videos || []).map((t) => t.url),
    inserts,
    file_ids,
    "premium_data": {
      "premium_collection_first_file_id": values?.premiumCollectionFirstFile || null,
      "premium_collection_second_file_id": values?.premiumCollectionSecondFile || null,
      "premium_central_stone_field_mobile_file_id": values?.premiumCentralStoneFieldMobileFile || null,
      "premium_certificate_title_file_id": values?.premiumCertificateTitleFile || null,
      "premium_stone_file_id": values?.premiumStoneFile || null,
      "premium_background_first_file_id": values?.premiumBackgroundFirstFile || null,
      "premium_background_second_file_id": values?.premiumBackgroundSecondFile || null,
      "premium_background_third_file_id": values?.premiumBackgroundThirdFile || null,
      "premium_background_mobile_file_id": values?.premiumBackgroundMobileFile || null,
      "premium_insert_mobile_file_id": values?.premiumInsertMobileFile || null,
      "premium_insert_file_id": values?.premiumInsertFile || null,
      "premium_central_stone_size": values?.premium_central_stone_size || "",
      "premium_central_stone_purity": values?.premium_central_stone_purity || "",
      "premium_certificate_title": values?.premium_certificate_title || "",
      "ru": {
        "premium_central_stone_title": (values?.translations || []).find((t) => t.locale === "ru")?.premium_central_stone_title || "",
        "premium_central_stone_description": (values?.translations || []).find((t) => t.locale === "ru")?.premium_central_stone_description || "",
        "premium_central_stone_field_description": (values?.translations || []).find((t) => t.locale === "ru")?.premium_central_stone_field_description || "",
        "premium_central_stone_color": (values?.translations || []).find((t) => t.locale === "ru")?.premium_central_stone_color || "",
        "premium_history_description": (values?.translations || []).find((t) => t.locale === "ru")?.premium_history_description || "",
        "premium_additional_description": (values?.translations || []).find((t) => t.locale === "ru")?.premium_additional_description || "",
        "premium_collection_description": (values?.translations || []).find((t) => t.locale === "ru")?.premium_collection_description || ""
      },
      "en": {
        "premium_central_stone_title": (values?.translations || []).find((t) => t.locale === "en")?.premium_central_stone_title || "",
        "premium_central_stone_description": (values?.translations || []).find((t) => t.locale === "en")?.premium_central_stone_description || "",
        "premium_central_stone_field_description": (values?.translations || []).find((t) => t.locale === "en")?.premium_central_stone_field_description || "",
        "premium_central_stone_color": (values?.translations || []).find((t) => t.locale === "en")?.premium_central_stone_color || "",
        "premium_history_description": (values?.translations || []).find((t) => t.locale === "en")?.premium_history_description || "",
        "premium_additional_description": (values?.translations || []).find((t) => t.locale === "en")?.premium_additional_description || "",
        "premium_collection_description": (values?.translations || []).find((t) => t.locale === "en")?.premium_collection_description || ""
      },
      "kaz": {
        "premium_central_stone_title": (values?.translations || []).find((t) => t.locale === "kaz")?.premium_central_stone_title || "",
        "premium_central_stone_description": (values?.translations || []).find((t) => t.locale === "kaz")?.premium_central_stone_description || "",
        "premium_central_stone_field_description": (values?.translations || []).find((t) => t.locale === "kaz")?.premium_central_stone_field_description || "",
        "premium_central_stone_color": (values?.translations || []).find((t) => t.locale === "kaz")?.premium_central_stone_color || "",
        "premium_history_description": (values?.translations || []).find((t) => t.locale === "kaz")?.premium_history_description || "",
        "premium_additional_description": (values?.translations || []).find((t) => t.locale === "kaz")?.premium_additional_description || "",
        "premium_collection_description": (values?.translations || []).find((t) => t.locale === "kaz")?.premium_collection_description || ""
      },
    },
    "stone_data": {
      "stone_background_of_the_gemological_description_file_id": values?.stoneBackgroundOfTheGemologicalDescriptionFile || null,
      "stone_rim_background_file_ids": values?.stoneRimBackgroundFiles || [],
      "stone_version_file_id": values?.stoneVersionFile || null,
      "stone_certificate_file_id": values?.stoneCertificateFile || null,
      "stone_field_file_id": values?.stoneFieldFile || null,
      "stone_certificate": values?.stone_certificate || "",
      "ru": {
        "stone_origin": (values?.translations || []).find((t) => t.locale === "ru")?.stone_origin || "",
        "stone_size": (values?.translations || []).find((t) => t.locale === "ru")?.stone_size || "",
        "stone_form": (values?.translations || []).find((t) => t.locale === "ru")?.stone_form || "",
        "stone_gentrification": (values?.translations || []).find((t) => t.locale === "ru")?.stone_gentrification || "",
        "stone_transparency": (values?.translations || []).find((t) => t.locale === "ru")?.stone_transparency || "",
        "stone_cut_type": (values?.translations || []).find((t) => t.locale === "ru")?.stone_cut_type || "",
        "stone_color": (values?.translations || []).find((t) => t.locale === "ru")?.stone_color || "",
        "stone_field": (values?.translations || []).find((t) => t.locale === "ru")?.stone_field || "",
      }
    }
  };
  if (!product.premiumCollectionFirstFile) {
    delete product.premium_data
  }
  if (!product.stoneFieldFile) {
    delete product.stone_data
  }

  delete product.premiumBackgroundFirstFile;
  delete product.premiumCollectionSecondFile;
  delete product.premiumCentralStoneFieldMobileFile;
  delete product.premiumCertificateTitleFile;
  delete product.premiumBackgroundFirstFile;
  delete product.premiumBackgroundSecondFile;
  delete product.premiumBackgroundThirdFile;
  delete product.premiumBackgroundMobileFile;
  delete product.premiumInsertMobileFile;
  delete product.premiumInsertFile;
  delete product.premium_central_stone_size;
  delete product.premium_central_stone_purity;
  delete product.premium_certificate_title;
  delete product.premiumCollectionFirstFile;

  delete product.stoneBackgroundOfTheGemologicalDescriptionFile;
  delete product.stoneBackgroundOfTheGemologicalDescriptionMobileFile;
  delete product.stoneCertificateFile;
  delete product.stoneFieldFile;
  delete product.stoneRimBackgroundFiles;
  delete product.stoneVersionFile;
  delete product.stoneFieldFile;

  return product
}
const getBodyApiFromClient = async (values) => {
  values.size = values.size || '';
  values.weight = values.weight || '';
  values.videos = (values.videos || []).filter((t) => !!t);

  let file_ids = [];
  let fileIndex = 0;
  for (const file of values.file_ids) {
    if (file.id) {
      const fileId = await changeFileSort(file.id, fileIndex);
      file_ids.push(file.id);
    } else {
      const fileId = await uploadFile(file.file, {
        type: file.fileType,
        order: fileIndex
      });
      file_ids.push(fileId);
    }
    fileIndex = fileIndex + 1;
  }
  values.file_ids = file_ids;

  // Данные премиум товара
  if (Object.keys(values?.premium_data || {}).length > 0) {
    let premium_data = values?.premium_data || {};
    for (const key of Object.keys(premium_data)) {
      const file = premium_data[key];
      if (['premium_central_stone_size', 'premium_central_stone_purity', 'premium_certificate_title', 'ru', 'en', 'kaz'].includes(key)) {
        break;
      }
      if (file?.id) {
        premium_data[key] = file.id;
      } else if (file) {
        premium_data[key] = await uploadFile(file, {type: "image"});
      } else {
        premium_data[key] = null;
      }
    };
    values.premium_data = premium_data;
  }

  // Данные камня товара
  if (Object.keys(values?.stone_data || {}).length > 0) {
    let stone_data = values?.stone_data || {};
    for (const key of Object.keys(stone_data)) {
      const file = stone_data[key];
      if (!['ru', 'en', 'kaz', 'stone_certificate'].includes(key)) {
        if (key === "stone_rim_background_file_ids") {
          let stone_rim_background_file_ids = [];
          for (const key of Object.keys(file)) {
            const _file = file[key];
            let fileId = "";
            if (_file?.id) {
              console.log('_file?.id: ', _file?.id)
              fileId = _file.id;
            }
            else if (_file) {
              fileId = await uploadFile(_file, {type: "image"});
            }
            else {
              fileId = null;
            }
            stone_rim_background_file_ids.push(fileId)
          }
          stone_data[key] = stone_rim_background_file_ids;
        }
        else if (file?.id) {
          stone_data[key] = file.id;
        }
        else if (file) {
          stone_data[key] = await uploadFile(file, {type: "image"});
        }
        else {
          stone_data[key] = null;
        }
      }
    };
    stone_data.stone_background_of_the_gemological_description_mobile_file_id = stone_data.stone_background_of_the_gemological_description_file_id;
    values.stone_data = stone_data;
  }

  values.inserts = (values.inserts || []).map((t) => {
    return {
      ...t,
      country_ids: (t?.country_ids || []).map((a) => a?.id || a)
    }
  })

  // Данные по ценам гарнитура
  const getPricesLocale = function (locale) {
    let price = Boolean((values?.products || []).length > 0) ? 0 : values?.[locale]?.price;
    let oldPrice = Boolean((values?.products || []).length > 0) ? 0 : values?.[locale]?.old_price;
    if (!price && (values?.products || []).length > 0) {
      (values?.products || []).map((product) => {
        price = price + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.price || 0);
        oldPrice = oldPrice + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.old_price || 0);
      })
    };
    return {
      price,
      old_price: oldPrice
    }
  };
  values.en = {
    ...values.en,
    ...getPricesLocale('en')
  };
  values.ru = {
    ...values.ru,
    ...getPricesLocale('ru')
  };
  values.kaz = {
    ...values.kaz,
    ...getPricesLocale('kaz')
  };

  return values
}

export {
  getBodyClientFromApi,
  getBodyApiFromClient
}
