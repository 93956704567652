import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
  RouteWithLayout
} from "../components";
import {
  Default as DefaultLayout
} from "../layouts";
import {
  Products as ProductsPage,
  Product as ProductPage,

  Metals as MetalsPage,
  Metal as MetalPage,

  Stones as StonesPage,
  Stone as StonePage,

  Categories as CategoriesPage,
  Category as CategoryPage,

  Collections as CollectionsPage,
  Collection as CollectionPage,

  Headsets as HeadsetsPage,
  Headset as HeadsetPage,

  Settings as SettingsPage,

  Mestorozhdeniya as MestorozhdeniyaPage,
  MestorozhdeniyaForm as MestorozhdeniyaFormPage,

  Branches as BranchesPage,
  BranchesForm as BranchesFormPage,

  StoneTypes as StoneTypesPage,
  StoneTypesForm as StoneTypesFormPage,

  Logs as LogsPage,

  Users as UsersPage,
  UsersForm as UsersFormPage
} from "../pages";

const pages = [
  {
    path: '/products',
    component: ProductsPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/products/create',
    component: ProductPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/products/:id',
    component: ProductPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/metals',
    component: MetalsPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/metals/create',
    component: MetalPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/metals/:id',
    component: MetalPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/stones',
    component: StonesPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/stones/create',
    component: StonePage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/stones/:id',
    component: StonePage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/categories',
    component: CategoriesPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/categories/create',
    component: CategoryPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/categories/:id',
    component: CategoryPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/collections',
    component: CollectionsPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/collections/create',
    component: CollectionPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/collections/:id',
    component: CollectionPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/headsets',
    component: HeadsetsPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/headsets/create',
    component: HeadsetPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/headsets/:id',
    component: HeadsetPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/mestorozhdeniya',
    component: MestorozhdeniyaPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/mestorozhdeniya/create',
    component: MestorozhdeniyaFormPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/mestorozhdeniya/:id',
    component: MestorozhdeniyaFormPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/branches',
    component: BranchesPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/branches/create',
    component: BranchesFormPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/branches/:id',
    component: BranchesFormPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/stone-types',
    component: StoneTypesPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/stone-types/create',
    component: StoneTypesFormPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/stone-types/:id',
    component: StoneTypesFormPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/settings',
    component: SettingsPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/logs',
    component: LogsPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },

  {
    path: '/users',
    component: UsersPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/users/create',
    component: UsersFormPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
  {
    path: '/users/:id',
    component: UsersFormPage,
    layout: DefaultLayout,
    rules: [],
    exact: true
  },
];

const MainRoutes = () => {
  return (
    <Switch>
      {
        pages.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/products"/>
    </Switch>
  );
};

export default MainRoutes;
