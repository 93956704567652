import React, {Component} from "react";
import {
	Box,
	Grid,
	Typography
} from "@mui/material";
import {
	Table,
	Filter
} from "./components";
import agent from "../../../../agent/agent";
import queryString from "query-string";

const initFilter = {
	"filters[model]": "",
	"filters[model_id]": "",
	page: 1,
	per_page: 20
};

class Logs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			logs: [],
			filter: {...initFilter},
			pagination: {
				total: 0,
				count: 0
			},
			isLoading: true
		};
	}

	componentDidMount = async () => {
		await this.parseFilterUrl();
		await this.getLogs();
	}

	getLogs = async () => {
		this.setState({ isLoading: true });
		const filter = this.getFilter();
		const res = await agent.get(`/api/logs?${filter}`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});
		await this.setState({
			logs: res?.data || [],
			isLoading: false,
			pagination: {total: res?.meta?.total || 0, count: res?.meta?.last_page || 0}
		});
		this.setFilterUrl();
	}

	getFilter = () => {
		const filter = {...this.state.filter};

		let list = [];
		Object.keys(filter).map((filterKey) => {
			const value = filter?.[filterKey];
			if (!!value) {
				list.push(`${filterKey}=${value}`);
			}
		})

		return list.join("&")
	}
	setFilterUrl = () => {
		const filterString = this.getFilter();

		const pathname = window?.location?.pathname || "";
		const newPathname = [pathname, filterString].filter((t) => !!t).join("?");

		window.history.replaceState(null, null, newPathname);
	}
	parseFilterUrl = () => {
		const locationSearch = this.props?.location?.search || "";
		let filter = queryString.parse(locationSearch, {
			arrayFormat: "bracket"
		});
		this.setState({
			filter: {
				...initFilter,
				...filter
			}
		});
	}
	resetFilter = async () => {
		await this.setState({ filter: {...initFilter} });
		await this.getLogs();
	}
	changeFilter = async (filter, isFastStart) => {
		await this.setState({filter});
		if (!isFastStart) {
			return
		}
		await this.getLogs();
	}

	render() {
		const {
			logs,
			filter,
			pagination,
			isLoading
		} = this.state;

		return (
			<>
				<Grid container alignItems="center" justifyContent="space-between" mb={2}>
					<Grid item>
						<Typography variant="h1">Логирование</Typography>
					</Grid>
				</Grid>

				<Filter
					filter={filter}
					onSearch={this.getLogs}
					onReset={this.resetFilter}
					onChange={this.changeFilter}
				/>

				<Box mt={2}/>

				<Table
					data={logs}
					filter={filter}
					pagination={pagination}
					isLoad={isLoading}

					onChangeFilter={this.changeFilter}
				/>

			</>
		);
	}
}

export default Logs
