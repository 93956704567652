import React from "react";
import {
  Autocomplete, FormHelperText,
  TextField
} from "@mui/material";
import agent from "../../../agent/agent";

class AutocompleteCountry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],

      isLoad: false
    };
  }

  componentDidMount = async () => {
    if (Boolean(this.props.value)) {
      await this.getOptions();
      if (this.props.onChangeActiveFilterCountry) {
        this.props.onChangeActiveFilterCountry((this.state.list || []).find((t) => t.external_id === this.props.value)?.name);
      }
    }
  }

  getOptions = async () => {
    if ((this.state?.list || []).length > 0) {
      return null
    }

    this.setState({isLoad: true});

    let list = await agent.get(`/api/countries?locale=ru&per_page=999`).then((res) => {
      return res.data?.data || []
    }).catch(() => {
      return []
    });
    list = list.map((t) => {
      return {
        ...t,
        ru: (t?.translations || []).find((b) => b.locale === 'ru')?.name || "",
        en: (t?.translations || []).find((b) => b.locale === 'en')?.name || "",
        kaz: (t?.translations || []).find((b) => b.locale === 'kaz')?.name || "",
      }
    })

    this.setState({
      list,
      isLoad: false
    });
  }

  onChange = (value) => {
    console.log('value: ', value);

    if (this.props.multiple) {
      const parseValue = [...value].map((item) => {
        return item?.external_id || item
      })

      this.props.onChange({
        target: {
          name: this.props.name,
          value: parseValue || []
        }
      }, value);
    }
    if (!this.props.multiple) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: value?.external_id || null
        }
      }, value);
    }
  }

  _getOptionLabel = (row) => {
    const options = this.state.list;
    const locale = localStorage.getItem('locale') || 'ru';

    if (typeof row !== 'object') {
      const item = options.find((t) => String(t.external_id || t.id) === String(row));
      if (!item) {
        return ""
      }
      return `${item?.[locale] || item?.name}`
    }

    return `${row?.[locale] || row.name}`
  }
  _filterOptions = (list, params) => {
    const inputValue = params?.inputValue || "";

    return [...list].filter((t) => {

      if (!inputValue) {
        return true
      }

      const nameLowerCase = t.name.toLowerCase();
      const inputValueLowerCase = inputValue.toLowerCase();

      return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

    })
  }

  render() {
    const {
      value,
      name,

      ...commonProps
    } = this.props;
    const {
      list,
      isLoad
    } = this.state;

    return (
      <>

        <Autocomplete
          {...this.props}

          value={value}
          disablePortal
          options={list}
          onChange={(event, value) => this.onChange(value, name)}
          name={name}
          variant="outlined"
          fullWidth
          disableClearable
          autoComplete={false}
          loading={isLoad}
          onOpen={this.getOptions}
          limitTags={1}

          getOptionLabel={this._getOptionLabel}
          filterOptions={this._filterOptions}

          renderInput={(params) => <TextField
            {...params}
            label="Страна"
            fullWidth
            error={Boolean(commonProps.error)}
          />}
        />

        {Boolean(commonProps.error || commonProps.helperText) && (
          <FormHelperText error={commonProps.error}>
            {commonProps.helperText}
          </FormHelperText>
        )}

      </>
    );
  }

}

export default AutocompleteCountry
