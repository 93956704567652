import React from "react";
import {
  Box,

  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Home as HomeIcon,
  PermMedia as PermMediaIcon,
  Psychology as PsychologyIcon,
  Description as DescriptionIcon,
  DriveFileRenameOutline as DriveFileRenameOutlineIcon
} from "@mui/icons-material";
import clsx from "clsx";
import {
  useLocation
} from "react-router-dom";
import queryString from "query-string";

const list = [
  {
    Icon: HomeIcon,
    title: "Основная параметры",
    search: "main-info",
  },
  {
    Icon: DriveFileRenameOutlineIcon,
    title: "Наименования товара",
    search: "name-product",
  },
  {
    Icon: DescriptionIcon,
    title: "Описание товара",
    search: "product-description",
  },
  {
    Icon: PermMediaIcon,
    title: "Изображения",
    search: "images",
  },
  {
    Icon: PsychologyIcon,
    title: "SEO и Meta",
    search: "seo-meta",
  },
  {
    Icon: PsychologyIcon,
    title: "Товары",
    search: "product-ids",
  }
];

const NavigationMenu = (props) => {
  const {
    tab,
    onChange
  } = props;
  const classes = useStyles();
  const search = useLocation()?.search || "";
  const activeSearch = tab || queryString.parse(search)?.content || "main-info";



  return (
    <Box className={classes.root}>
      <MenuList>
        {list.map((element, index) => (
          <MenuItem
            key={`element-${index}`}
            className={clsx({
              [classes.menuItem]: true,
              'active': activeSearch === element.search
            })}
            onClick={onChange.bind(this, element.search)}
          >
            <ListItemIcon>
              <element.Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <span style={{whiteSpace: "initial"}}>
                {element.title}
              </span>
            </ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    padding: "10px 0",
    borderRadius: "10px"
  },

  menuItem: {
    "& .MuiSvgIcon-root": {
      color: "#8B8C8D"
    },
    "& .MuiTypography-root": {
      color: "#8B8C8D"
    },

    "&.active": {
      "& .MuiSvgIcon-root": {
        color: "#d6b46e"
      },
      "& .MuiTypography-root": {
        color: "#d6b46e"
      },
    }
  },
}));

export default React.memo(NavigationMenu)
