import React from "react";
import {
  Box,
  Grid,
  Chip,
  Switch,
  Select,
  MenuItem,
  TextField,
  FormControl,
  FormHelperText
} from "@mui/material";
import {
   makeStyles
} from "@mui/styles";
import {
  CKEditor,
   AutocompleteCategories
} from "../../../../../../components";
import {
  IMaskInput
} from "react-imask";

class MainInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {}
  }

  onChangeForm = (name, value) => {
    let values = {...this.props.values};
    values[name] = value;
    this.props.onChange(values);
  }
  onChangeSettings = (locale, name, value) => {
    let values = {...this.props.values};
    values[locale][name] = value;
    this.props.onChange(values);
  }

  render () {
    const {
      values,
      errors,
      touched
    } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <Chip label="RU" sx={{width: "50px"}}/>
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.ru?.is_hidden}
                title="Товар отключен"
                onChange={this.onChangeSettings.bind(this, 'ru',  'is_hidden')}
              />
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.ru?.is_novelty}
                title="Новинка"
                onChange={this.onChangeSettings.bind(this, 'ru',  'is_novelty')}
              />
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.ru?.is_price_visible}
                title="Цена видимая"
                onChange={this.onChangeSettings.bind(this, 'ru',  'is_price_visible')}
              />
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.ru?.is_sold}
                title="Продано"
                onChange={this.onChangeSettings.bind(this, 'ru',  'is_sold')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <Chip label="EN" sx={{width: "50px"}}/>
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.en?.is_hidden}
                title="Товар отключен"
                onChange={this.onChangeSettings.bind(this, 'en',  'is_hidden')}
              />
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.en?.is_novelty}
                title="Новинка"
                onChange={this.onChangeSettings.bind(this, 'en',  'is_novelty')}
              />
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.en?.is_price_visible}
                title="Цена видимая"
                onChange={this.onChangeSettings.bind(this, 'en',  'is_price_visible')}
              />
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.en?.is_sold}
                title="Продано"
                onChange={this.onChangeSettings.bind(this, 'en', 'is_sold')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <Chip label="KAZ" sx={{width: "50px"}}/>
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.kaz?.is_hidden}
                title="Товар отключен"
                onChange={this.onChangeSettings.bind(this, 'kaz', 'is_hidden')}
              />
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.kaz?.is_novelty}
                title="Новинка"
                onChange={this.onChangeSettings.bind(this, 'kaz', 'is_novelty')}
              />
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.kaz?.is_price_visible}
                title="Цена видимая"
                onChange={this.onChangeSettings.bind(this, 'kaz', 'is_price_visible')}
              />
            </Grid>
            <Grid item xs={3}>
              <SectionSingleCheckBox
                value={values?.kaz?.is_sold}
                title="Продано"
                onChange={this.onChangeSettings.bind(this, 'kaz', 'is_sold')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SectionInput
            value={values?.sku}
            title="Идентификационный номер"
            errors={errors?.sku}
            touched={touched?.sku}
            onChange={this.onChangeForm.bind(this, 'sku')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values?.model}
            title="Модель"
            errors={errors?.model}
            touched={touched?.model}
            onChange={this.onChangeForm.bind(this, 'model')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionSelect
            value={values?.gender}
            title="Пол"
            errors={errors?.gender}
            touched={touched?.gender}
            options={[{title: "Мужской", value: "male"}, {title: "Женский", value: "female"}]}
            onChange={this.onChangeForm.bind(this, 'gender')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values?.order}
            title="Сортировка"
            type="number"
            errors={errors?.order}
            touched={touched?.order}
            onChange={this.onChangeForm.bind(this, 'order')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionCategories
            value={values?.category_id}
            title="Категория товара"
            errors={errors?.category_id}
            touched={touched?.category_id}
            onChange={this.onChangeForm.bind(this, 'category_id')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values?.size}
            title="Размер"
            scale={2}
            errors={errors?.size}
            touched={touched?.size}
            onChange={this.onChangeForm.bind(this, 'size')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values?.weight}
            title="Вес"
            scale={2}
            errors={errors?.weight}
            touched={touched?.weight}
            onChange={this.onChangeForm.bind(this, 'weight')}
          />
        </Grid>
      </Grid>
    )
  }
}
const SectionInput = React.memo((props) => {
  const {
    type,
    title,
    value,
    onChange,
    align,
    errors,
    touched,

    ...otherProps
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(value);
  }

  return (
    <Box className={classes.whiteSection}>
      <Grid container spacing={4} alignItems={align || 'center'}>
        <Grid item xs={2}>
          <Box pt={Boolean(align) ? 1 : 0}>{title}</Box>
        </Grid>
        <Grid item xs={10}>
          <TextField
            {...otherProps}

            value={value}
            size="small"
            placeholder="..."
            type={type || 'string'}
            fullWidth
            hiddenLabel
            onChange={handleChange}
            error={Boolean(touched && errors)}
            helperText={touched && errors}
          />
        </Grid>
      </Grid>
    </Box>
  )
});
const SectionDescription = React.memo((props) => {
  const {
    type,
    title,
    value,
    onChange,
    errors,
    touched,
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(value);
  }

  return (
    <Box className={classes.whiteSection}>
      <Grid container spacing={4} alignItems="flex-start">
        <Grid item xs={2}>
          <Box pt={2}>
            {title}
          </Box>
        </Grid>
        <Grid item xs={10}>
          <CKEditor
            value={value}
            size="small"
            placeholder="..."
            type={type || 'string'}
            fullWidth
            hiddenLabel
            onChange={handleChange}
          />
          {Boolean(touched && errors) && (
            <FormHelperText error={true}>{touched && errors}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </Box>
  )
});
const SectionSelect = React.memo((props) => {
  const {
    title,
    value,
    options,
    onChange,
    errors,
    touched,
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(value);
  }

  return (
    <Box className={classes.whiteSection}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={2}>{title}</Grid>
        <Grid item xs={10}>
          <FormControl
            fullWidth
            hiddenLabel
            size="small"
            error={Boolean(touched && errors)}
          >
            <Select
              value={value}
              onChange={handleChange}
            >
              {options.map((t) => (
                <MenuItem value={t.value}>{t.title}</MenuItem>
              ))}
            </Select>

            {Boolean(touched && errors) && (
              <FormHelperText error={true}>
                {touched && errors}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
});
const SectionInputMask = React.memo((props) => {
  const {
    type,
    title,
    value,
    scale,
    disabled,
    onChange,

    errors,
    touched
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(value);
  }

  return (
    <Box className={classes.whiteSection}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={2}>{title}</Grid>
        <Grid item xs={10}>
          <TextField
            value={value}
            size="small"
            placeholder="..."
            type={type || 'string'}
            fullWidth
            hiddenLabel
            inputProps={{
              scale: scale || 0,
              radix: '.'
            }}
            InputProps={{
              inputComponent: TextMaskCustom
            }}
            disabled={disabled}
            error={Boolean(touched && errors)}
            helperText={touched && errors}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  )
});
const SectionCategories = React.memo((props) => {
  const {
    title,
    value,
    options,
    onChange,
    errors,
    touched,
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(value);
  }

  return (
    <Box className={classes.whiteSection}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={2}>
          {title}
        </Grid>
        <Grid item xs={10}>
          <AutocompleteCategories
            value={value}
            size="small"
            error={Boolean(touched && errors)}
            helperText={touched && errors}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  )
});
const SectionSingleCheckBox = React.memo((props) => {
  const {
    title,
    value,
    onChange
  } = props;
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onChange(newValue);
  }

  return (
    <div className={classes.singleCheckBox} onClick={() => handleChange(null, !value)}>
      <div className="title">{title}</div>
      <Switch checked={value}/>
    </div>
  )
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, scale, radix, ...other } = props;

  const handleOnAccept = (value) => {
    onChange({ target: { name: props.name, value } })
  }

  return (
    <IMaskInput
      {...other}
      mask={Number}
      thousandsSeparator=" "
      radix={radix || '.'}
      mapToRadix={[',', '.']}

      scale={scale}
      unmask={true}

      inputRef={ref}
      onAccept={handleOnAccept}
      overwrite
    />
  );
});

const useStyles = makeStyles(() => ({
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  },
  singleCheckBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "5px 24px",
    boxSizing: "border-box",
    cursor: "pointer",

    "& .title": {
      fontSize: "16px"
    },
    "&:hover": {
      boxShadow: "0 0 5px -1px rgba(0,0,0,0.5)"
    }
  },
}));

export {
  SectionInput,
  SectionInputMask,
  SectionDescription,
  SectionSingleCheckBox
}
export default MainInfo
