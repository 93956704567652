import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,


  Typography,
  Button,
  Grid,
  Box,
} from "@mui/material";

class DialogConfirmAction extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      message: "",

      labelSuccess: "",
      labelCancel: "",

      onSuccess: null,
      onCancel: null,


      open: false
    }
  }


  open = ({title, message, labelSuccess, labelCancel, onSuccess, onCancel}) => {
    this.setState({
      title: title || "",
      message: message || "",
      labelSuccess: labelSuccess || "",
      labelCancel: labelCancel || "",
      onSuccess: onSuccess || null,
      onCancel: onCancel || null,

      open: true
    })
  }
  close = () => {
    this.setState({
      open: false
    })
  }


  onSuccess = () => {
    this.onCancel();

    this.state?.onSuccess();
  }
  onCancel = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const {
      title,
      message,

      labelSuccess,
      labelCancel,

      open
    } = this.state;

    return (
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth={true}


        onClose={this.onCancel}
      >

        <DialogTitle sx={{backgroundColor: "#D6B46E"}}>
          <Typography variant="h3" sx={{color: "white"}}>
            {title || "Подтверждение"}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box px={1} py={3}>
            <Typography
              variant="subtitle1"
              dangerouslySetInnerHTML={{
                __html: message || "Вы действительно хотите это сделать?"
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="outlined" onClick={this.onCancel}>
                {labelCancel || "Отмена"}
              </Button>
            </Grid>
            <Grid item>
              <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="contained" onClick={this.onSuccess}>
                {labelSuccess || "Подтвердить"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>

      </Dialog>
    )
  }
}

export default DialogConfirmAction
