import React from "react";
import {
  Box,
  Grid
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";

class Images extends React.PureComponent {
  constructor(props) {
    super(props);

  }

  render () {
    const {
      values,
      errors,
      touched,
      classes
    } = this.props;

    return (
      <Box className={classes.whiteSection}>

      </Box>
    )
  }
}

const styles = {
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  },
};
Images = withStyles(styles)(Images);

export default Images
