import React from "react";
import {
  Box,
  Grid,
  Popover,
  Typography,
  Button,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Update as UpdateIcon,
  Subject as SubjectIcon,
  Category as CategoryIcon,
  Settings as SettingsIcon,
  Inventory as InventoryIcon,
  PlaylistAdd as PlaylistAddIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";
import {Link, withRouter} from "react-router-dom";
import clsx from "clsx";
import PopupState, {bindTrigger, bindPopover} from "material-ui-popup-state";
import {compose} from "recompose";
import {connect} from "react-redux";
import languages from "../../../../constants/languages";
import {changeLocale} from "../../../../states/languages";
import {setUser} from "../../../../states/global";
import agent from "../../../../agent/agent";
import {setCookie} from "../../../../helper/cookie";

class Header extends React.PureComponent {

  exitUser = () => {
    localStorage.removeItem('token');
    agent.defaults.headers['Authorization'] = "";
    setCookie('admin-auth', '');
    this.props.setUser(null);
  }

  _menu = () => {
    const mathPath = this.props?.match?.path || "";
    let menu = [
      {
        icon: CategoryIcon,
        label: "Категории",
        path: "/categories",
        isActive: Boolean(mathPath.indexOf("/categories") > -1)
      },
      {
        icon: InventoryIcon,
        label: "Товары",
        path: "/products",
        isActive: Boolean(mathPath.indexOf("/products") > -1)
      },
      {
        icon: SubjectIcon,
        label: "Металлы",
        path: "/metals",
        isActive: Boolean(mathPath.indexOf("/metals") > -1)
      },
      {
        icon: SubjectIcon,
        label: "Камни",
        path: "/stones",
        isActive: Boolean(mathPath.indexOf("/stones") > -1)
      },
      {
        icon: PlaylistAddIcon,
        label: "Коллекции",
        path: "/collections",
        isActive: Boolean(mathPath.indexOf("/collections") > -1)
      },
      {
        icon: SettingsIcon,
        label: "Справочники",
        path: "/",
        items: [
          {
            icon: PlaylistAddIcon,
            label: "Месторождения",
            path: "/mestorozhdeniya",
            isActive: Boolean(mathPath.indexOf("/mestorozhdeniya") > -1)
          },
          {
            icon: PlaylistAddIcon,
            label: "Огранка",
            path: "/branches",
            isActive: Boolean(mathPath.indexOf("/branches") > -1)
          },
          {
            icon: PlaylistAddIcon,
            label: "Тип камня",
            path: "/stone-types",
            isActive: Boolean(mathPath.indexOf("/stone-types") > -1)
          },
        ],
        isActive: Boolean(
          mathPath.indexOf("/mestorozhdeniya") > -1 ||
          mathPath.indexOf("/stone-types") > -1 ||
          mathPath.indexOf("/branches") > -1
        )
      },
      {
        icon: SettingsIcon,
        label: "Система",
        path: "/",
        items: [
          {
            icon: PlaylistAddIcon,
            label: "Настройки",
            path: "/settings",
            isActive: Boolean(mathPath.indexOf("/settings") > -1)
          },
          {
            icon: PlaylistAddIcon,
            label: "Логирование",
            path: "/logs",
            isActive: Boolean(mathPath.indexOf("/logs") > -1)
          },
          {
            icon: PlaylistAddIcon,
            label: "Пользователи",
            path: "/users",
            isActive: Boolean(mathPath.indexOf("/users") > -1)
          },
        ],
        isActive: Boolean(
          mathPath.indexOf("/settings") > -1 ||
          mathPath.indexOf("/logs") > -1 ||
          mathPath.indexOf("/users") > -1
        )
      },
    ]

    return menu
  }

  render() {
    const {
      classes,
      userRole,
      locale,
      setLocale
    } = this.props;

    return (
      <Box className={classes.root}>
        <Grid container spacing={1}>

          <Grid item>
            <Box className={classes.logo}>
              <Box className={classes.logoLabel}>admin</Box>
              <img src={require("../../../../assets/logos/header-logo.svg").default}/>
            </Box>
          </Grid>

          <Grid item flex={1}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item flex={1}>
                  <Grid container spacing={2}>
                    {
                      this._menu().map((item, index) => (
                        <Grid item key={`menu-item-${index}`}>

                          {Boolean((item.items || []).length <= 0) && (
                            <Link to={item.path}>
                              <Box className={clsx({
                                [classes.menuItem]: true,
                                [classes.menuItemDisable]: Boolean(item.path === "#"),
                              })}>
                                <Box className={classes.menuItemIcon}>
                                  <item.icon sx={{color: "#D6B46E"}}/>
                                </Box>
                                <Typography
                                  className={classes.menuItemLabel}>{item.label}</Typography>

                                {Boolean(item.isActive) && (
                                  <Box className={classes.menuItemIndicator}/>
                                )}
                              </Box>
                            </Link>
                          )}

                          {Boolean((item.items || []).length > 0) && (
                            <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <div>
                                  <Box {...bindTrigger(popupState)}>
                                    <Box className={clsx({
                                      [classes.menuItem]: true,
                                      [classes.menuItemDropDown]: true,
                                      [classes.menuItemDisable]: Boolean(item.path === "#"),
                                    })}>
                                      {Boolean(item.iconPath) && (
                                        <Box className={classes.menuItemIcon}>
                                          <img src={item.iconPath}/>
                                        </Box>
                                      )}
                                      <Typography
                                        className={classes.menuItemLabel}>{item.label}</Typography>
                                      <Box className={classes.menuItemIconDropDown}>
                                        <KeyboardArrowDownIcon color="primary"/>
                                      </Box>

                                      {Boolean(item.isActive) && (
                                        <Box className={classes.menuItemIndicator}/>
                                      )}
                                    </Box>
                                  </Box>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    {item.items.map((item, indexItem) => (
                                      <Link to={item.path}>
                                        <Box className={clsx({
                                          [classes.dropDownItem]: true
                                        })}>
                                          <Typography
                                            className={classes.menuItemLabel}>{item.label}</Typography>
                                        </Box>
                                      </Link>
                                    ))}
                                  </Popover>
                                </div>
                              )}
                            </PopupState>
                          )}

                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <PopUpSelectLanguage
              locale={locale}
              onChange={setLocale}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" sx={{marginTop: "12px"}} onClick={this.exitUser}>
              Выйти
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
const PopUpSelectLanguage = React.memo((props) => {
  const {
    locale,
    onChange
  } = props;

  const handleChange = (newLocale) => {
    onChange(newLocale);
    window.location.reload();
  }

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Button
            size="small"
            variant="contained"
            {...bindTrigger(popupState)}
            sx={{marginTop: "12px"}}
          >
            Выбранный язык: {locale}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuList>
              {languages.map((item) => (
                <MenuItem
                  onClick={handleChange.bind(this, item.locale)}
                >
                  <ListItemIcon>
                    {item.locale}
                  </ListItemIcon>
                  <ListItemText>
                    {item.label}
                  </ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Popover>
        </div>
      )}
    </PopupState>
  )
});

const styles = {
  root: {
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
    width: "100%",
    paddingRight: 20
  },

  logo: {
    display: "flex"
  },
  logoLabel: {
    height: 64,
    backgroundColor: "#D6B46E",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 20,

    fontSize: 24,
    lineHeight: "24px",
    color: "white",
    fontWeight: "600"
  },
  logoIcon: {},

  menuItem: {
    display: "flex",
    alignItems: "center",
    height: 64,
    position: "relative"
  },
  menuItemDropDown: {
    cursor: "pointer"
  },
  menuItemDisable: {
    opacity: 0.4,
    cursor: "not-allowed"
  },
  menuItemIcon: {
    width: 24,
    height: 24
  },
  menuItemIconDropDown: {
    width: 20,
    height: 20,
    marginLeft: 12
  },
  menuItemLabel: {
    fontSize: 16,
    lineHeight: "26px",
    fontWeight: "600",
    color: "#22252D",
    marginLeft: 8
  },
  menuItemIndicator: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#D6B46E",
    height: 5
  },

  dropDownItem: {
    padding: "8px 16px"
  }
}

const headerStyles = withStyles(styles)(Header);
const StylesRouteHeader = withRouter(headerStyles);

export default compose(
  connect(
    state => ({
      userRole: state?.global?.userRole || "Administrator",
      locale: state.languages?.locale
    }),
    dispatch => ({
      setUser: (val) => dispatch(setUser(val)),
      setLocale: (locale) => changeLocale(locale)
    }),
  ),
)(StylesRouteHeader)
