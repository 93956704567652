import React from "react";
import {
  Box,
  Grid,
  Chip,
  Button,
  Collapse,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  AutocompleteStones,
  AutocompleteCountry,
  AutocompleteDirectory
} from "../../../../../../components";
import {IMaskInput} from "react-imask";
import clsx from "clsx";

const initInsert = {
  stone_id: "",
  proportions: "",
  defectiveness: "",
  size: "",
  quantity: "",
  country_ids: [],
  branch_id: "",
  stone_type_id: "",
  ru: {
    // branch: "", // огранка
    // type_stone: "",
    color: "",
  },
  en: {
    // branch: "",
    // type_stone: "",
    color: "",
  },
  kaz: {
    // branch: "",
    // type_stone: "",
    color: "",
  },
};

class Inserts extends React.PureComponent {

  addInsert = () => {
    let values = {...this.props.values};
    values.inserts.push(JSON.parse(JSON.stringify({ ...initInsert })));
    this.props.onChange(values);
  }
  editInsert = (index, data) => {
    let values = {...this.props.values};
    values.inserts[index] = data;
    this.props.onChange(values);
    this.changeStoneData();
  }
  editInsertMain = (index, value) => {
    let values = {...this.props.values};
    let inserts = [...values.inserts].map((t) => {
      return {
        ...t,
        is_central: 0
      }
    });
    inserts[index].is_central = Number(value);
    values.inserts = inserts;
    this.props.onChange(values);
    this.changeStoneData();
  }
  deleteInsert = (index) => {
    let values = {...this.props.values};
    values.inserts.splice(index, 1);
    this.props.onChange(values);
  }

  changeStoneData = () => {
    const { values } = this.props;

    const centerInsert = (values?.inserts || []).find((t) => Boolean(t.is_central));
    if (!centerInsert) {
      return
    }

    if (!values?.stone_data) {
      return
    }

    let newValues = {...values};
    newValues.stone_data.ru.stone_origin = centerInsert?.countries?.[0]?.name || "";

    const branchRu = (centerInsert?.branch?.translations || []).find((t) => t.locale === 'ru') || null;
    if (!!branchRu) {
      newValues.stone_data.ru.stone_form = branchRu?.name || ""
    }

    const branchEn = (centerInsert?.branch?.translations || []).find((t) => t.locale === 'en') || null;
    if (!!branchEn) {
      newValues.stone_data.en.stone_form = branchEn?.name || ""
    }

    const branchKaz = (centerInsert?.branch?.translations || []).find((t) => t.locale === 'kaz') || null;
    if (!!branchKaz) {
      newValues.stone_data.kaz.stone_form = branchKaz?.name || ""
    }

    this.props.onChange(newValues);
  }

  render () {
    const {
      values,
      errors,
      touched,
    } = this.props;

    return (
      <Grid container spacing={2}>
        {(values?.inserts || []).map((insert, index) => (
          <Grid key={`ElementInsert-${ insert.id }-${ index }`} item xs={12}>
            <ElementInsert
              data={insert}
              errors={errors?.inserts?.[index]}
              touched={touched?.inserts?.[index]}
              onDelete={this.deleteInsert.bind(this, index)}
              onChange={this.editInsert.bind(this, index)}
              onChangeMain={this.editInsertMain.bind(this, index)}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button variant="contained" size="small" fullWidth onClick={this.addInsert}>
            Добавить
          </Button>
        </Grid>
      </Grid>
    )
  }
}

let timeOutSubmitData = null;
const ElementInsert = React.memo((props) => {
  const {
    data,
    errors,
    touched,
    onDelete,
    onChange,
    onChangeMain
  } = props;
  const [localData, setLocalData] = React.useState({...data});
  const [isOpen, setOpen] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(localData)) {
      setLocalData({...data});
    }
  }, [data]);

  const handleSubmitData = (newData) => {
    setSubmitting(true);
    clearTimeout(timeOutSubmitData);
    timeOutSubmitData = setTimeout(() => {
      onChange(newData);
      setSubmitting(false);
    }, 1000);
  }

  const handleChange = ({ target }, selectValue) => {
    const { name, value } = target;
    let newData = {...localData};
    newData[name] = value;

    if (name === "branch_id") {
      newData.branch = selectValue;
    }
    if (name === "country_ids") {
      newData.countries = selectValue;
    }

    setLocalData(newData);
    handleSubmitData(newData);
  }
  const handleChangeLocale = (locale, { target }) => {
    const { name, value } = target;
    let newData = {...localData};
    newData[locale][name] = value;
    setLocalData(newData);
    handleSubmitData(newData);
  }

  return (
    <Box
      className={clsx({
        [classes.whiteSection]: true,
        "loading": isSubmitting
      })}
    >
      <Grid container spacing={1}>
        <Grid item xs={1.5}>
          <FormControlLabel
            checked={Boolean(data.is_central)}
            control={<Checkbox />}
            label="Центральная вставка"
            onChange={onChangeMain.bind(this, !Boolean(data.is_central))}
          />
        </Grid>
        <Grid item xs={2.5}>
          <AutocompleteStones
            value={localData.stone_id}
            name="stone_id"
            size="small"
            error={Boolean(touched?.stone_id && errors?.stone_id)}
            helperText={touched?.stone_id && errors?.stone_id}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            value={String(localData.quantity)}
            name="quantity"
            size="small"
            label="Количество"
            fullWidth
            error={Boolean(touched?.quantity && errors?.quantity)}
            helperText={touched?.quantity && errors?.quantity}
            inputProps={{
              scale: 0
            }}
            InputProps={{
              inputComponent: TextMaskCustom
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            value={String(localData.size)}
            name="size"
            size="small"
            label="Вес, ct"
            fullWidth
            error={Boolean(touched?.size && errors?.size)}
            helperText={touched?.size && errors?.size}
            inputProps={{
              scale: 3,
              normalizeZeros: false
            }}
            InputProps={{
              inputComponent: TextMaskCustom
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            value={localData.defectiveness}
            name="defectiveness"
            size="small"
            label="Дефектность"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            value={localData.proportions}
            name="proportions"
            size="small"
            label="Пропорции"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" size="small" onClick={onDelete}>
                Удал.
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" size="small" onClick={() => setOpen(!isOpen)}>
                {isOpen ? 'Свер.' : 'Развер.'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpen}>
        {Boolean(isOpen) && (
          <Grid container spacing={1} mt={1}>
            <Grid item xs={4}>
              <AutocompleteCountry
                value={localData.country_ids || []}
                name="country_ids"
                size="small"
                multiple
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteDirectory
                value={localData.branch_id}
                size="small"
                label="Огранка"
                api="/branches"
                name="branch_id"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteDirectory
                value={localData.stone_type_id}
                size="small"
                label="Тип камня"
                api="/stone-types"
                name="stone_type_id"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} container spacing={1}>
              <Grid item>
                <Chip label="RU" sx={{width: "50px"}}/>
              </Grid>
              <Grid item sx={{flex: 1}}>
                <TextField
                  value={localData.ru?.color}
                  name="color"
                  size="small"
                  label="Цвет"
                  fullWidth
                  onChange={handleChangeLocale.bind(this, 'ru')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item>
                <Chip label="EN" sx={{width: "50px"}}/>
              </Grid>
              <Grid item sx={{flex: 1}}>
                <TextField
                  value={localData.en?.color}
                  name="color"
                  size="small"
                  label="Цвет"
                  fullWidth
                  onChange={handleChangeLocale.bind(this, 'en')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item>
                <Chip label="KAZ" sx={{width: "50px"}}/>
              </Grid>
              <Grid item sx={{flex: 1}}>
                <TextField
                  value={localData.kaz?.color}
                  name="color"
                  size="small"
                  label="Цвет"
                  fullWidth
                  onChange={handleChangeLocale.bind(this, 'kaz')}
                />
              </Grid>
            </Grid>

            {Boolean(false) && (
              <>
                <Grid item xs={12} container spacing={1}>
                  <Grid item>
                    <Chip label="RU" sx={{width: "50px"}}/>
                  </Grid>
                  <Grid item sx={{flex: 1}}>
                    <TextField
                      value={localData.ru?.branch}
                      name="branch"
                      size="small"
                      label="Огранка"
                      fullWidth
                      onChange={handleChangeLocale.bind(this, 'ru')}
                    />
                  </Grid>
                  <Grid item sx={{flex: 1}}>
                    <TextField
                      value={localData.ru?.color}
                      name="color"
                      size="small"
                      label="Цвет"
                      fullWidth
                      onChange={handleChangeLocale.bind(this, 'ru')}
                    />
                  </Grid>
                  <Grid item sx={{flex: 1}}>
                    <TextField
                      value={localData.ru?.type_stone}
                      name="type_stone"
                      size="small"
                      label="Тип камня"
                      fullWidth
                      onChange={handleChangeLocale.bind(this, 'ru')}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item>
                    <Chip label="EN" sx={{width: "50px"}}/>
                  </Grid>
                  <Grid item sx={{flex: 1}}>
                    <TextField
                      value={localData.en?.branch}
                      name="branch"
                      size="small"
                      label="Огранка"
                      fullWidth
                      onChange={handleChangeLocale.bind(this, 'en')}
                    />
                  </Grid>
                  <Grid item sx={{flex: 1}}>
                    <TextField
                      value={localData.en?.color}
                      name="color"
                      size="small"
                      label="Цвет"
                      fullWidth
                      onChange={handleChangeLocale.bind(this, 'en')}
                    />
                  </Grid>
                  <Grid item sx={{flex: 1}}>
                    <TextField
                      value={localData.en?.type_stone}
                      name="type_stone"
                      size="small"
                      label="Тип камня"
                      fullWidth
                      onChange={handleChangeLocale.bind(this, 'en')}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item>
                    <Chip label="KAZ" sx={{width: "50px"}}/>
                  </Grid>
                  <Grid item sx={{flex: 1}}>
                    <TextField
                      value={localData.kaz?.branch}
                      name="branch"
                      size="small"
                      label="Огранка"
                      fullWidth
                      onChange={handleChangeLocale.bind(this, 'kaz')}
                    />
                  </Grid>
                  <Grid item sx={{flex: 1}}>
                    <TextField
                      value={localData.kaz?.color}
                      name="color"
                      size="small"
                      label="Цвет"
                      fullWidth
                      onChange={handleChangeLocale.bind(this, 'kaz')}
                    />
                  </Grid>
                  <Grid item sx={{flex: 1}}>
                    <TextField
                      value={localData.kaz?.type_stone}
                      name="type_stone"
                      size="small"
                      label="Тип камня"
                      fullWidth
                      onChange={handleChangeLocale.bind(this, 'kaz')}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Collapse>
    </Box>
  )
});
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, scale, ...other } = props;

  const handleOnAccept = (value) => {
    if (value === other.value) {
      return
    }
    onChange({ target: { name: props.name, value } })
  }

  return (
    <IMaskInput
      {...other}
      mask={Number}
      thousandsSeparator=" "
      radix=","
      scale={scale}

      inputRef={ref}
      onAccept={handleOnAccept}
    />
  );
});

const useStyles = makeStyles(() => ({
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",

    "&.loading": {
      background: "#fbfbfb"
    }
  },
}));

export default Inserts
