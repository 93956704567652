import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  AutocompleteCollections
} from "../../../../../../components";

class Collections extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {}
  }

  addCollection = () => {
    let values = {...this.props.values};
    values.collection_ids.push('');
    this.props.onChange(values);
  }
  changeCollection = (index, value) => {
    console.log('index, value: ', index, value);
    let values = {...this.props.values};
    values.collection_ids[index] = value;
    this.props.onChange(values);
  }
  removeCollection = (index) => {
    let values = {...this.props.values};
    values.collection_ids.splice(index, 1);
    this.props.onChange(values);
  }

  render () {
    const {
      values,
      classes
    } = this.props;

    return (
      <Box className={classes.whiteSection}>
        <Typography variant="h3" mb={2}>Товар входит в коллекции</Typography>
        <Grid container spacing={2}>
          {(values?.collection_ids || []).map((collectionId, index) => (
            <Grid item xs={12}>
              <CollectionItem
                collectionId={collectionId}
                onChange={this.changeCollection.bind(this, index)}
                onRemove={this.removeCollection.bind(this, index)}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              size="small"
              variant="contained"
              fullWidth
              onClick={this.addCollection}
            >
              Добавить коллекцию
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }
}
const CollectionItem = React.memo((props) => {
  const {
    collectionId,
    onChange,
    onRemove
  } = props;

  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(value);
  }

  return (
    <Grid container spacing={2}>
      <Grid item sx={{flex: 1}}>
        <AutocompleteCollections
          value={collectionId}
          size="small"
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="outlined"
          onClick={onRemove}
        >Удалить</Button>
      </Grid>
    </Grid>
  )
})

const styles = {
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  },
};
Collections = withStyles(styles)(Collections);

export default Collections
