import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Backdrop,
	Container,
	TextField,
	Typography,
	CircularProgress, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import {
	Formik
} from "formik";
import {
	Notification,
	NotificationTypes
} from "../../../../common/Notification";
import * as Yup from "yup";
import agent from "../../../../agent/agent";

const initForm = {
	name: '',
	email: '',
	password: '',
	role_ids: ""
};
class UsersForm extends Component {
	constructor(props) {
		super(props);

		const userId = props?.match?.params?.id;
		this.state = {
			roles: [],
			form: {...initForm},
			userId: userId,
			isInit: !Boolean(userId),
			isCreate: !Boolean(userId),
			isBackdrop: false
		};
		this.refFormik = React.createRef();
	}

	componentDidMount = async () => {
		await this.getRoles();
		await this.initForm();
	}

	getRoles = async () => {
		const roles = await agent.get('/api/roles').then((res) => {
			return res.data?.data || []
		}).catch(() => {
			return []
		});
		this.setState({
			roles
		})
	}

	initForm = async () => {
		if (this.state.isCreate) {
			return
		}
		const res = await agent.get(`/api/users/${this.state.userId}`).then((res) => {
			return res.data?.data
		}).catch(() => {
			return null
		});
		if (!res) {
			Notification({
				type: NotificationTypes.error,
				message: "Пользователь не найден"
			})
			return
		}

		const form = {
			...res,
			role_ids: res?.role?.pivot?.role_id
		}

		this.setState({
			form: {...form},
			isInit: true
		})
	}
	changeForm = ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	}
	submitForm = async (values) => {
		this.setState({ isBackdrop: true });
		const { userId, isCreate } = this.state;
		const actionMethod = Boolean(isCreate) ? "post" : "put";
		const actionUrl = Boolean(isCreate) ? '/api/users' : `/api/users/${ userId }`;
		values.role_ids = [values.role_ids];
		const res = await agent({
			url: actionUrl,
			method: actionMethod,
			data: values
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res.error) {
			this.setState({ isBackdrop: false });
			let errorMessage = "";
			if (typeof res?.error?.data?.data === "object") {
				let _msg = [];
				Object.keys(res?.error?.data?.data || {}).map((errorKey) => {
					_msg.push(...res?.error?.data?.data[errorKey])
				});
				errorMessage = _msg.join(";<br/>");
			}

			Notification({
				type: NotificationTypes.error,
				message: errorMessage || "Ошибка сервера"
			})
			return
		}
		this.setState({ isBackdrop: false });
		Notification({
			type: NotificationTypes.success,
			message: isCreate ? "Пользователь успешно создан" : "Пользователь успешно изменен"
		});
		this.props.history.push("/users")
	}

	render () {
		const {
			form,
			roles,
			isInit,
			isCreate,
			isBackdrop
		} = this.state;

		return (
			<Container>

				<Typography variant="h1">
					{isCreate ? 'Создание пользователя' : 'Редактирование пользователя'}
				</Typography>

				<Box mt={1}/>

				<VisibleContent visible={Boolean(isInit)}>
					<Formik
						innerRef={this.refFormik}
						initialValues={form}
						validationSchema={isCreate ? validationSchemaCreate : validationSchemaEdit}
						onSubmit={this.submitForm}
					>{(props) => {
						const {
							values,
							errors,
							touched,

							handleSubmit
						} = props;
						return (
							<Box borderRadius="10px" bgcolor="white" boxShadow="0 0 3px -1px black" p={2}>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<TextField
											value={values.name}
											name="name"
											label="Имя"
											size="small"
											variant="outlined"
											error={Boolean(touched?.name && errors?.name)}
											helperText={touched?.name && errors?.name}
											fullWidth
											onChange={this.changeForm}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											value={values.email}
											name="email"
											label="Email"
											size="small"
											variant="outlined"
											error={Boolean(touched?.email && errors?.email)}
											helperText={touched?.email && errors?.email}
											fullWidth
											onChange={this.changeForm}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											value={values.password}
											name="password"
											label="Пароль"
											size="small"
											variant="outlined"
											error={Boolean(touched?.password && errors?.password)}
											helperText={touched?.password && errors?.password}
											fullWidth
											onChange={this.changeForm}
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControl
											fullWidth
											size="small"
										>
											<InputLabel>Роль пользователя</InputLabel>
											<Select
												value={values.role_ids}
												name="role_ids"
												label="Роль пользователя"
												onChange={this.changeForm}
											>
												{roles.map((item) => (
													<MenuItem value={item.id}>
														{item.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<Button fullWidth size="small" variant="outlined">
											Отменить
										</Button>
									</Grid>
									<Grid item xs={6}>
										<Button fullWidth size="small" variant="contained" onClick={handleSubmit}>
											{isCreate ? 'Создать' : 'Редактировать'}
										</Button>
									</Grid>
								</Grid>
							</Box>
						)
					}}</Formik>
				</VisibleContent>

				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>

			</Container>
		);
	}
};
const VisibleContent = React.memo(({ visible, children }) => {
	if (!visible) {
		return null
	}
	return children
})

const validationSchemaEdit = Yup.object().shape({
	name: Yup.string().required('Заполните поле'),
	role_ids: Yup.string().required('Заполните поле'),
	email: Yup.string().email('Некоретно введен Email').required('Заполните поле'),
});
const validationSchemaCreate = Yup.object().shape({
	name: Yup.string().required('Заполните поле'),
	role_ids: Yup.string().required('Заполните поле'),
	password: Yup.string().required('Заполните поле'),
	email: Yup.string().email('Некоретно введен Email').required('Заполните поле'),
});

export default UsersForm
