import {combineReducers} from 'redux';

import global from '../states/global';
import languages from '../states/languages';

const rootReducer = combineReducers({
  global,
  languages
});

export default rootReducer
