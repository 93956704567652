import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,

	Box,
	Grid,
	Tooltip,
	Skeleton,
	IconButton,
	Pagination,
} from "@mui/material";
import {
	Edit as EditIcon,
	Delete as DeleteIcon
} from "@mui/icons-material";
import {Link} from "react-router-dom";

const TableCustom = (props) => {
	const {
		data,
		filter,
		isLoad,
		isAdmin,
		totalPages,

		onRemove
	} = props;

	const handleChangePage = (event, page) => {
		let _filter = {...filter};
		_filter.page = page;
		props.onChangeFilter(_filter, true);
	}

	return (
		<>

			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Имя</TableCell>
						<TableCell>Email</TableCell>
						<TableCell/>
					</TableRow>
				</TableHead>
				<TableBody>
					{!isLoad && data.map((item) => (
						<RowData
							item={item}
							isAdmin={isAdmin}
							onRemove={onRemove}
						/>
					))}
					{isLoad && <RowsLoading/>}
				</TableBody>
			</Table>

			<Box mt={2}/>

			<Grid container justifyContent="flex-end">
				<Grid item>
					<Pagination
						page={filter.page}
						count={totalPages}
						onChange={handleChangePage}
					/>
				</Grid>
			</Grid>

		</>
	)
}
const RowData = React.memo((props) => {
	const {
		item,
		isAdmin,
		onRemove
	} = props;

	return (
		<TableRow>
			<TableCell>{item.name}</TableCell>
			<TableCell>{item.email}</TableCell>
			<TableCell>
				{Boolean(isAdmin) && (
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Tooltip arrow title="Редактировать">
								<IconButton color="primary" component={Link} to={`/users/${item.id}`}>
									<EditIcon/>
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip arrow title="Удалить">
								<IconButton color="error" onClick={onRemove.bind(this, item, false)}>
									<DeleteIcon/>
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				)}
			</TableCell>
		</TableRow>
	)
})
const RowsLoading = React.memo(() => {
	return new Array(10).fill(1).map(() => (
		<TableRow>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
		</TableRow>
	))
});

export default React.memo(TableCustom)
