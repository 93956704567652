import React from "react";
import {
	Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import logsObjectType from "../../../../../../constants/logsObjectType";

const Filter = (props) => {
	const {
		filter,
		onSearch,
		onReset
	} = props;
	const classes = useStyles();

	const handleChange = ({ target }) => {
		const { name, value } = target;
		let newFilter = {...filter};
		newFilter[name] = value;
		newFilter.page = 1;
		props.onChange(newFilter);
	}

	return (
		<Box className={classes.root}>
			<Grid container wrap="nowrap">
				<Grid item sx={{flex: 1}}>
					<Grid container spacing={2}>
						<Grid item xs={2.4}>
							<FormControl fullWidth size="small">
								<InputLabel>Тип объекта</InputLabel>
								<Select
									name="filters[model]"
									value={filter['filters[model]']}
									label="Тип объекта"
									onChange={handleChange}
								>
									{logsObjectType.map((item) => (
										<MenuItem value={item.value}>{item.label}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={2.4}>
							<TextField
								value={filter['filters[model_id]']}
								name="filters[model_id]"
								label="ID объекта"
								size="small"
								placeholder="..."
								fullWidth
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid container spacing={1}>
						<Grid item>
							<Button variant="outlined" size="small" onClick={onReset}>
								Сбросить
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" size="small" onClick={onSearch}>
								Поиск
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: "white",
		padding: "15px 20px",
		boxSizing: "border-box",
		borderRadius: "10px"
	}
}));

export default React.memo(Filter)
