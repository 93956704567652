import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Box,
	Grid,
	Button,
	Collapse,
	Skeleton,
	Pagination,
	Typography
} from "@mui/material";
import {
	ArrowRightAlt as ArrowRightAltIcon
} from "@mui/icons-material";
import moment from "moment";
import {Link} from "react-router-dom";
import logsObjectType from "../../../../../../constants/logsObjectType";

const TableCustom = (props) => {
	const {
		data,
		filter,
		pagination,
		isLoad,

		onChangeFilter
	} = props;

	const handleChangePage = (e, p) => {
		let _filter = {...filter};
		_filter.page = p;
		onChangeFilter(_filter, true);
	}

	return (
		<>

			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Пользователь</TableCell>
						<TableCell>Тип</TableCell>
						<TableCell>ID</TableCell>
						<TableCell>Дата</TableCell>
						<TableCell align="right"/>
					</TableRow>
				</TableHead>
				<TableBody>
					{!isLoad && data.map((item) => <RowData
						item={item}
					/>)}
					{isLoad && <RowsLoading/>}
				</TableBody>
			</Table>

			<Box mt={2}/>

			<Grid container justifyContent="flex-end">
				<Grid item>
					<Pagination
						page={Number.parseFloat(filter.page || 1)}
						count={pagination.count}
						onChange={handleChangePage}
					/>
				</Grid>
			</Grid>

		</>
	)
}
const RowData = React.memo((props) => {
	const {
		item
	} = props;
	const [isOpen, setOpen] = React.useState(false);
	const linkModel = logsObjectType.find((t) => t.value === item.model)?.link;

	const handleChangeOpen = () => {
		setOpen(!isOpen);
	}

	return (
		<>
			<TableRow>
				<TableCell>
					{item?.editorUser?.name || item?.editorUser?.email}
				</TableCell>
				<TableCell>
					{item.model}
				</TableCell>
				<TableCell>
					<Link to={`${linkModel}${item.model_id}`} target="_blank">
						{item.model_id}
					</Link>
				</TableCell>
				<TableCell>
					{moment(item.created_at).format("DD.MM.YYYY HH:mm:ss")}
				</TableCell>
				<TableCell align="right">
					<Button size="small" variant="text" onClick={handleChangeOpen}>
						{isOpen ? 'Свернуть изменения' : 'Развернуть изменения'}
					</Button>
				</TableCell>
			</TableRow>

			<TableCell colSpan={5} style={{padding: 0}}>
				<Collapse in={isOpen}>
					<Box px={2} py={2}>
						Изменено поле: <span style={{fontWeight: 'bold'}}>{item.attribute}</span>;
						<Grid container spacing={2} alignItems="center" mt={0.5}>
							<Grid item>
								<Typography variant="caption">Старое значение</Typography>
								<Typography variant="h6">{item.old_value}</Typography>
							</Grid>
							<Grid item>
								<ArrowRightAltIcon/>
							</Grid>
							<Grid item>
								<Typography variant="caption">Новое значение</Typography>
								<Typography variant="h6">{item.new_value}</Typography>
							</Grid>
						</Grid>
					</Box>
				</Collapse>
			</TableCell>
		</>
	)
})
const RowsLoading = React.memo(() => {
	return new Array(10).fill(1).map(() => (
		<TableRow>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
		</TableRow>
	))
})

export default React.memo(TableCustom)
