import agent from "../agent/agent";

const downloadFile = async (linkFile) => {
  const file = await fetch(linkFile, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  const fileBlob = await file.blob();

  const fileName = (file?.url || "").split("/").pop();

  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(fileBlob);
  link.download = fileName;

  document.body.appendChild(link);

  link.click();
}
const uploadFile = async (file, { type, order }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  formData.append("is_single", 1);
  formData.append("order", order || 1);
  const res = await agent.post('/api/files', formData).then((res) => {
    return res.data?.data?.id || null
  }).catch(() => {
    return null
  });
  return res
}
const changeFileSort = async (fileId, order) => {
  const res = await agent.put(`/api/files/${fileId}`, {
    order: order,
    is_single: 1
  }).then((res) => {
    return res.data?.data?.id || null
  }).catch(() => {
    return null
  });
  return res
}


export {
  uploadFile,
  downloadFile,
  changeFileSort
}
