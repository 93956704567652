import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Backdrop,
	Typography,
	CircularProgress
} from "@mui/material";
import {
	Table
} from "./components";
import {
	DialogConfirmAction
} from "../../../../components";
import {
	Link
} from "react-router-dom";
import {
	Notification,
	NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

const initFilter = {
	page: 1,
	per_page: 20
};
class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			users: [],
			filter: {...initFilter},
			totalPages: 0,
			isLoading: true,
			isBackdrop: false
		};
		this.refDialogConfirmAction = React.createRef();
	}

	componentDidMount = async () => {
		await this.getUsers();
	}

	getUsers = async () => {
		await this.setState({ isLoading: true });
		const filter = this.getFilter();
		const res = await agent.get(`/api/users?${filter}`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});
		this.setState({
			users: res?.data || [],
			totalPages: res?.meta?.last_page,
			isLoading: false
		});
	}
	removeUser = async (user, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmAction.current.open({
				message: `Вы действительно хотите удалить пользователя "${user.name}"?`,
				onSuccess: this.removeUser.bind(this, user, true)
			})
			return
		}
		this.setState({ isBackdrop: true });
		const res = await agent.delete(`/api/users/${user.id}`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res.error) {
			this.setState({ isBackdrop: false });
			Notification({
				type: NotificationTypes.error,
				message: "Ошибка удаления пользователя"
			});
			return
		}
		await this.getUsers();
		this.setState({ isBackdrop: false });
		Notification({
			type: NotificationTypes.success,
			message: "Пользователь успешно удален"
		});
	}

	getFilter = () => {
		const { filter } = this.state;
		let str = [];
		Object.keys(filter).map((filterKey) => {
			str.push(`${filterKey}=${filter[filterKey]}`);
		});
		return str.join("&")
	}
	changeFilter = async (filter, isFastStart) => {
		await this.setState({ filter });
		if (!isFastStart) {
			return
		}
		await this.getUsers();
	}

	render() {
		const {
			users,
			filter,
			totalPages,
			isLoading,
			isBackdrop,
		} = this.state;
		const {
			isAdmin
		} = this.props;

		return (
			<>

				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item>
						<Typography variant="h1">Пользователи</Typography>
					</Grid>
					{Boolean(isAdmin) && (
						<Grid item>
							<Button variant="contained" size="small" component={Link} to="/users/create">
								Создать пользователя
							</Button>
						</Grid>
					)}
				</Grid>

				<Box mb={2}/>

				<Table
					data={users}
					filter={filter}
					isAdmin={isAdmin}
					isLoad={isLoading}
					totalPages={totalPages}
					onRemove={this.removeUser}
					onChangeFilter={this.changeFilter}
				/>

				<DialogConfirmAction
					ref={this.refDialogConfirmAction}
				/>
				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}

export default Users
