import React from "react";
import {
  Chip,
  Grid,
  Divider
} from "@mui/material";
import {
  SectionInput
} from "../MainInfo";

class AdditionalInformation extends React.PureComponent {

  onChangeForm = (locale, name, value) => {
    let values = {...this.props.values};
    values[locale][name] = value;
    this.props.onChange(values);
  }

  render () {
    const {
      values
    } = this.props;

    return (
      <Grid container spacing={1}>

        {/* RU */}
        <Grid item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="RU"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            title="Заголовок для скидки"
            value={values['ru']['discount_name']}
            onChange={this.onChangeForm.bind(this, 'ru', 'discount_name')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            title="Сообщение для скидки"
            value={values['ru']['discount_description']}
            onChange={this.onChangeForm.bind(this, 'ru', 'discount_description')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            title="Текст предварительного просмотра"
            value={values['ru']['preview_text']}
            onChange={this.onChangeForm.bind(this, 'ru', 'preview_text')}
          />
        </Grid>

        {/* EN */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="EN"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            title="Заголовок для скидки"
            value={values['en']['discount_name']}
            onChange={this.onChangeForm.bind(this, 'en', 'discount_name')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            title="Сообщение для скидки"
            value={values['en']['discount_description']}
            onChange={this.onChangeForm.bind(this, 'en', 'discount_description')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            title="Текст предварительного просмотра"
            value={values['en']['preview_text']}
            onChange={this.onChangeForm.bind(this, 'en', 'preview_text')}
          />
        </Grid>

        {/* KAZ */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="KAZ"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            title="Заголовок для скидки"
            value={values['kaz']['discount_name']}
            onChange={this.onChangeForm.bind(this, 'kaz', 'discount_name')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            title="Сообщение для скидки"
            value={values['kaz']['discount_description']}
            onChange={this.onChangeForm.bind(this, 'kaz', 'discount_description')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            title="Текст предварительного просмотра"
            value={values['kaz']['preview_text']}
            onChange={this.onChangeForm.bind(this, 'kaz', 'preview_text')}
          />
        </Grid>

      </Grid>
    )
  }
}

export default AdditionalInformation
