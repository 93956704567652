// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Mestorozhdeniya from './Mestorozhdeniya';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Mestorozhdeniya);
