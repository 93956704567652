import React from "react";
import {
  Grid,
  Chip,
  Divider
} from "@mui/material";
import {
  SectionInput
} from "../../../../products/Product/components/MainInfo";

class NameSection extends React.PureComponent {

  onChangeForm = (locale, name, value) => {
    let values = {...this.props.values};
    values[locale][name] = value;
    this.props.onChange(values);
  }

  render () {
    const {
      values,
      errors,
      touched
    } = this.props;

    return (
      <Grid container spacing={1}>

        {/* RU */}
        <Grid item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="RU"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['ru']['name']}
            title="Наименование"
            errors={errors?.ru?.name}
            touched={touched?.ru?.name}
            onChange={this.onChangeForm.bind(this, 'ru', 'name')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['ru']['name_one']}
            title="Наименование в единственном числе"
            onChange={this.onChangeForm.bind(this, 'ru', 'name_one')}
          />
        </Grid>

        {/* EN */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="EN"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['en']['name']}
            title="Наименование"
            onChange={this.onChangeForm.bind(this, 'en', 'name')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['en']['name_one']}
            title="Наименование в единственном числе"
            onChange={this.onChangeForm.bind(this, 'en', 'name_one')}
          />
        </Grid>

        {/* KAZ */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="KAZ"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['kaz']['name']}
            title="Наименование"
            onChange={this.onChangeForm.bind(this, 'kaz', 'name')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values['kaz']['name_one']}
            title="Наименование в единственном числе"
            onChange={this.onChangeForm.bind(this, 'kaz', 'name_one')}
          />
        </Grid>
      </Grid>
    )
  }
}

export default NameSection
